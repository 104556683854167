import {
  styled,
  TableCell,
} from "@mui/material";
import SelectField from "controls/global/select-field";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { PricingProduct } from "entities/UIModel";
import React from "react";
import {
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricingTable from "utils/custom-hooks/usePricingTable";
import {
  PricingColumnName,
  PricingType,
  ProductType,
} from "utils/data/enum";
import {
  convertToNumber,
  formatCurrency,
} from "utils/shared";
import PricingProductNumberCell from "./PricingProductNumberCell";
import PricingProductReissueCell from "./PricingProductReissueCell";
import TransCodeCell from "./TransCodeCell";
import { usePricingErrorMessageContext } from "pages/file/PricingErrorMessageProvider";
import CustomTooltip from "controls/global/custom-tooltip";

const StyledTableBodyCell = styled(TableCell)({
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  padding: padding.zero,
  "&:first-child": {
    paddingLeft: padding.small2,
  },
  "& .MuiTextField-root": {
    maxWidth: "180px",
    minWidth: "120px",
    textOverflow: "ellipsis",
  },
  "& .MuiInputBase-root": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const CurrencyCell = styled(TableCell)({
  borderBottom: "none",
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  padding: `${padding.small1} ${padding.zero}`,
  textAlign: "right",
  textOverflow: "ellipsis",
  "& :first-child": {
    maxWidth: "130px", // To show up to $999,999,999.11
  },
});

type Props = {
  pricing: PricingProduct;
  index: number;
  isIntegratedPricing: boolean;
  isOverride: boolean;
  isFileLocked: boolean;
  isReadOnly: boolean;
  isPricingUpdateable: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  handleRateTypeChange: (e: any | null, index: number) => void;
  handleReissueChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    pricingProduct: PricingProduct,
    pricingIndex: number
  ) => void;
  handleIsReissueClick: (index: number) => void;
  handleActualFeeBlur: (e: any, index: number) => Promise<void>;
  // handleActualFeeOnChange: (e: any, index: number) => void;
  handleRiskRateBlur: (e: any, index: number) => void;
  handleAgentRetentionBlur: (e: any, index: number) => void;
  handleTotalDueBlur: (e: any, index: number) => void;
};

export default function PricingProductItem({
  pricing,
  index,
  isIntegratedPricing,
  isOverride,
  isFileLocked,
  isReadOnly,
  isPricingUpdateable,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  handleRateTypeChange,
  handleReissueChange,
  handleIsReissueClick,
  handleActualFeeBlur,
  // handleActualFeeOnChange,
  handleRiskRateBlur,
  handleAgentRetentionBlur,
  handleTotalDueBlur,
}: Props) {
  const schema = `pricingProducts.${index}`;
  const { getValues, nameString } = useFormWrapper();  
  // const { validateField } = useTrigger();
  const { showLiability, hasRateTypes } = usePricingTable();
  const { state: { products } } = usePricingErrorMessageContext();
  const errorProductInfo = products.get(pricing.filePricingDetailId);

  const currentPricing = getValues(`pricingProducts.${index}`);
  
  const getRateTypes = () => {
    const pricingRateTypeData = getValues(
      nameString(`${schema}.pricingRateTypeData`)
    );

    const rateTypeData = pricingRateTypeData
      ? JSON.parse(pricingRateTypeData)
      : undefined;

    if (!rateTypeData) return [];
    return rateTypeData?.map((type: any) => ({
      text: type.Alias,
      value: type.Alias,
    }));
  };

  const isUpdateable = () => {
    if (isReadOnly) {
      return false;
    }

    if (pricing.isReadyToBeBilled === 1 && pricing.isBilled === 0) {

      if (!isIntegratedPricing) {
        return true;
      }

      if (isIntegratedPricing && !isStandaloneProduct()) {
        return isOverride;
      } else {
        return pricing.productType === ProductType.Jacket;
      }
    }
    return false;
  };

  // const isItemUpdateable: boolean = item.isBillable === 1 && item.isBilled === 0 &&  (!isIntegratedPricing
  //   ? true
  //   : isOverride);


  const isStandaloneProduct = () => {
    return (
      pricing.productType === ProductType.StandaloneEndorsement &&
      pricing.pricingType === PricingType.Product
    );
  };

  const isDisabled = (pricingColumnName: string) => {
    if (
      ( pricingColumnName === PricingColumnName.AgentRetention ||
        pricingColumnName === PricingColumnName.TotalDue) &&
        pricing.pricingType !== PricingType.Product
    )
      return true;

    if (isIntegratedPricing && !isOverride ) return true;

    if (
      pricingColumnName === PricingColumnName.ActualFee ||
      (displayRiskRateColumn &&
        pricingColumnName === PricingColumnName.RiskRate)
    )
      return false;

    return (!displayRiskRateColumn &&
      convertToNumber(currentPricing?.actualFee) !== 0) ||
      (displayRiskRateColumn &&
        convertToNumber(currentPricing?.actualRiskRate) !== 0)
      ? false
      : true;
  };

  // const validateFieldOnChange = ({
  //   target: { name },
  // }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   validateField(name);
  // };

  return (
    <>
      <StyledTableBodyCell align="left">
        <TooltipCell title={pricing.product} />
      </StyledTableBodyCell>
      {displayFieldIcon && (
        <StyledTableBodyCell align="left">
          {pricing.pricingType === PricingType.Product &&  errorProductInfo?.displayFieldIcon && !isOverride && !isReadOnly && !isFileLocked && (
            <CustomTooltip
              title={errorProductInfo?.toolTipMessage}
              disableHoverListener={!errorProductInfo?.showToolTip}
              placement="top"
              aria-label="lender-contact-info"
              arrow
            >
              <ReportProblemIcon style={{ color: errorProductInfo?.fieldIconColor }} />
            </CustomTooltip>
          )}
        </StyledTableBodyCell>
      )}
      {displayLiabilityColumn && (
        <CurrencyCell>
          {showLiability(pricing.pricingType, pricing.productType) &&
            <TooltipCell title={formatCurrency(pricing.liability)} />
          }
        </CurrencyCell>
      )}
      {displayRateTypeColumn && (
        <StyledTableBodyCell>
          {hasRateTypes(index) ? (
            <SelectField
              label=""
              name={`pricingProducts.${index}.pricingRateType`}
              options={getRateTypes()}
              allowsDelete={false}
              disabled={Boolean(isOverride)}
              onChange={(event: any) => {
                handleRateTypeChange(event, index);
              }}
              size="small"
            />
          ) : (
            <span />
          )}
        </StyledTableBodyCell>
      )}
      {displayReissueColumn && (
        <PricingProductReissueCell
          index={index}
          schema={schema}
          isOverride={isOverride}
          handleReissueChange={(e: React.ChangeEvent<HTMLInputElement>, index) => {handleReissueChange(e, pricing, index);}}
          handleIsReissueClick={handleIsReissueClick}
          isFileLocked={isFileLocked}
          isPricingUpdateable={isPricingUpdateable}
        />
      )}
      {displayTransCodeColumn && (
        <StyledTableBodyCell align="center">
          <TransCodeCell product={pricing} />
        </StyledTableBodyCell>
      )}
      <PricingProductNumberCell
        value={pricing.actualFee}
        name={`pricingProducts.${index}.actualFee`}
        isDisabled={isDisabled(PricingColumnName.ActualFee)}
        isUpdatable={isUpdateable()}
        notUpdatableClassName="actualFeeColumn"
        errorClassName="errorMessageBigRedFloating210"
        isStandalone={isStandaloneProduct()}
        // onChange={(
        //   event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        // ) => {
        //   handleActualFeeOnChange(event, index);
        // }}
        onBlur={async (
          event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          await handleActualFeeBlur(event, index);
        }}
      />
      {displayRiskRateColumn && (
        <PricingProductNumberCell
          value={pricing.actualRiskRate || 0}
          name={`pricingProducts.${index}.actualRiskRate`}
          isDisabled={isDisabled(PricingColumnName.RiskRate)}
          isUpdatable={isUpdateable()}
          isStandalone={isStandaloneProduct()}
          // onChange={validateFieldOnChange}
          onBlur={(
            event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            handleRiskRateBlur(event, index);
          }}
        />
      )}
      <PricingProductNumberCell
        value={pricing.agentRetention}
        name={`pricingProducts.${index}.agentRetention`}
        isDisabled={isDisabled(PricingColumnName.AgentRetention)}
        isUpdatable={isUpdateable()}
        isStandalone={isStandaloneProduct()}
        // onChange={validateFieldOnChange}
        onBlur={(
          event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          handleAgentRetentionBlur(event, index);
        }}
      />
      <PricingProductNumberCell
        value={pricing.totalDue}
        name={`pricingProducts.${index}.totalDue`}
        isDisabled={isDisabled(PricingColumnName.TotalDue)}
        isUpdatable={isUpdateable()}
        isStandalone={isStandaloneProduct()}
        // onChange={validateFieldOnChange}
        onBlur={(
          event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          handleTotalDueBlur(event, index);
        }}
      />
    </>
  );
}
