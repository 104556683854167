import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import useDocumentStore from "utils/context/ImageArchiveContext";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from "@mui/material";
import React, {
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  iconSize,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";

import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import { 
  menuIds
} from "pages/home/menu";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import {
  PERMISSIONS,
} from "utils/data/enum";
import { useConfigContext } from "utils/context/ConfigContextProvider";

interface IProps {
  isValidLogin: boolean;
  saveFileChangeRef?: any;
}

const StyledDivider = styled("div")({
  backgroundColor: colors.white,
  height: "39px",
  width: "2px",
});

const StyledPopper = styled(Popper)({
  alignItems: "center",
  background: gradients.blueGradient03,
  borderRadius: borderRadius.xlarge,
  color: colors.white,
  display: "flex",
  gap: gaps.xsmall2,
  minWidth: "160px",
  overflow: "inherit",
  padding: padding.zero,
  top: "28px !important",
  zIndex: zIndex.high1,
  "& .MuiPaper-root ": {
    borderRadius: borderRadius.xlarge,
    "&:before": {
      border: "solid transparent",
      borderBottomColor: colors.blue01,
      borderWidth: borderSize.xxxlarge,
      bottom: "100%",
      content: `""`,
      height: "0",
      left: "50%",
      marginLeft: `-${margin.large}`,
      pointerEvents: "none",
      position: "absolute",
      width: "0",
    },
    "& .MuiList-root": {
      display: "flex",
      background: gradients.blueGradient03,
      flexDirection: "column",
      borderRadius: borderRadius.xlarge,
      gap: gaps.xsmall2,
      "& .MuiMenuItem-root": {
        borderRadius: borderRadius.small,
        color: colors.white,
        margin: "auto",
        paddingBottom: padding.large1,
        paddingLeft: padding.large1,
        paddingRight: padding.large1,
        paddingTop: padding.large1,
        width: "90%",
        // "&:hover": {
        //   background: gradients.blueGradient03,
        //   color: colors.white,
        // },
      },
    },
  },
});

const UsernameButton = styled(Button)({
  color: colors.white,
  display: "flex",
  flexDirection: "row",
  gap: gaps.xsmall2,
  minWidth: iconSize.small1,
  padding: padding.zero,
  "& .username-container": {
    fontSize: fontSize.base,
    fontWeight: fontWeight.normal1,
    textTransform: "initial",
  },
  "& .MuiSvgIcon-root": {
    fontSize: iconSize.medium,
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const MyAppLink = ({ isValidLogin, saveFileChangeRef }: IProps) => {
  // const [ { isEditMode  }] = useDocumentStore();
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);


  const { redirect } = useNavRedirect();
  const [{ profileSettings }] = useProfileSettingsCache();
  
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);

  const hasPolicyAccessPermission = permissionCodes.includes(PERMISSIONS.POLICY_ACCESS);
  // not needed since we are already inside SCMain app
  // const hasSiteAcessPermission = permissionCodes.includes(PERMISSIONS.SITE_ACCESS);
  const hasAdminAccessPermission = permissionCodes.includes(PERMISSIONS.ADMIN_ACCESS);

  const { generalConfig: { pspDomain, adminDomain } } = useConfigContext();

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setPopperOpen(false);
    }
  };

  const handlePopperClick = (event: React.MouseEvent<HTMLElement>) => {
    // if(!isEditMode) {
      setPopperAnchorEl(event?.currentTarget);
      setPopperOpen((previousOpen) => !previousOpen);
    // }
  };

  const handleRedirectPSP = () => {
    redirect(pspDomain, saveFileChangeRef, false);
    setPopperOpen(false);
  };

  const handleRedirectAdmin = () => {
    redirect(adminDomain, saveFileChangeRef, false);
    setPopperOpen(false);
  };

  // not needed since we are already inside SCMain app
  // const handleRedirectMain = () => {
  //   redirect(mainDomain, saveFileChangeRef);
  //   setPopperOpen(false);
  // };

  useEffect(() => {
    popperAnchorEl?.focus();
  }, [popperOpen]);

  return (
    <>
      <StyledDivider/>  
      <UsernameButton onClick={handlePopperClick} id="myapp">
          <div className="username-container">
            My Apps
          </div>
        {!popperOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </UsernameButton>
      <StyledPopper
        open={popperOpen}
        anchorEl={popperAnchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={3} style={{width :"100%"}}>
              <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
                <MenuList
                  autoFocusItem={popperOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {hasAdminAccessPermission &&  (
                    <MenuItem data-menu-id={menuIds.menuRedirectAdmin} onClick={handleRedirectAdmin}>
                      Admin Portal
                    </MenuItem>
                  )}
                  {hasPolicyAccessPermission && (
                    <MenuItem data-menu-id={menuIds.menuRedirectPSP} onClick={handleRedirectPSP}>
                      Policy Services Portal
                    </MenuItem>
                  )}
                  {/* not needed since we are already inside SCMain app
                    {hasSiteAcessPermission && (
                    <MenuItem data-menu-id={menuIds.menuRedirectMain} onClick={handleRedirectMain}>
                      Connect Portal
                    </MenuItem>
                  )} */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow >
        )}
      </StyledPopper >
    </>
  );
};

export default MyAppLink;
