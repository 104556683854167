import {
  Box,
  IconButton,
  styled,
  Table,
  TableFooter,
  TableRow,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
// import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import QADialog from "controls/global/dialogs/qa-dialog";
import ReportAndPayConfirmationDialog from "controls/global/dialogs/report-and-pay-confirmation-dialog/ReportAndPayConfirmationDialog";
import FlashMessage from "controls/global/flash-message/FlashMessage";
import PdfViewer from "controls/global/pdf-viewer/PdfViewer";
import {
  ActionResult,
  Endorsement,
  PdfDocument,
  Pricing,
  PricingProduct,
  PricingProductDetail,
  SCFile,
  SCOrder,
} from "entities/UIModel";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import {
  cloneDeep,
  isEqual,
} from "lodash";
import {
  calculateRemittance,
  getCorrelationId,
  getSumPricingTaxes,
} from "pages/file/utils/helper";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { runValidation } from "pages/file/utils/yup/validator";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import {
  colors,
  defaultStyle,
  fontSize,
  fontWeight,
  gaps,
  padding,
} from "theme/defaultStyle";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useCompanyRemittance } from "utils/context/CompanyRemittanceContext";
import { useFilePage } from "utils/context/FilePageContext";
import { useFiles, useInitialValuesFiles } from "utils/context/FilesContext";
import { useFlashMessageActions } from "utils/context/FlashMessageContext";
import { useLookup } from "utils/context/LookupContext";
import { useOrder } from "utils/context/OrderContext";
import { usePayment } from "utils/context/PaymentContext";
import { usePricingCalculatorContext } from "utils/context/PricingCalculatorContext";
import { usePricingConfig } from "utils/context/PricingConfigContext";
import {
  useIssuedProductsCountUpdated,
  usePricingActions,
  usePricingInProgress,
  usePricingStates,
} from "utils/context/PricingContext";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import { default as useCreateFile } from "utils/custom-hooks/useCreateFile";
import { default as useFieldDisabler } from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import { default as usePricing } from "utils/custom-hooks/usePricing";
import { default as usePricingTable } from "utils/custom-hooks/usePricingTable";
import {
  DOMEventType,
  MapActionType,
  NotificationType,
  PricingConfigKey,
  PricingType,
  ProductAction,
  ProductType,
  ReportAndPayAction,
  ReportType,
  UIConstants,
} from "utils/data/enum";
import {
  convertToNumber,
  formatCurrency,
  hasValue,
} from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import { usePricingErrorMessageContext } from "../../PricingErrorMessageProvider";
import IntegratedPricing from "./integrated-pricing/IntegratedPricing";
import { default as PricingOriginalPolicyDialog } from "./original-policy/PricingOriginalPolicyDialog";
import { default as PricingEmptyBodyRow } from "./pricing-table/pricing-table-body/PricingEmptyBodyRow";
import PricingTableBody from "./pricing-table/pricing-table-body/PricingTableBody";
import PricingTableFooter from "./pricing-table/pricing-table-footer/PricingTableFooter";
import PricingTableHeader from "./pricing-table/pricing-table-header/PricingTableHeader";
import PricingTableHeaderNotification from "./pricing-table/pricing-table-header/PricingTableHeaderNotification";
import PricingButton from "./PricingButton";
import { ReportAndPayCriteria } from "entities/ApiModel/ReportAndPayCriteria";
// import ActionButton from "controls/global/action-button";

type Props = {
  onNavigate: (url: string) => void;
};

const PricingContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& .hide": {
    display: "none",
  },
});

const TextIconContainer = styled("div")({
  display: "flex",
});

const StyledTableContainer = styled(Box)({
  width: "100%",
  overflowX: "auto",
  "& table": {
    maxWidth: "100%",
    "& .MuiTableCell-root": {
      "&:first-child": {
        maxWidth: 0, // Product column can grow to fill remaining space.
        minWidth: "400px",
      },
      "&:not(:first-child)": {
        width: 0, // All columns after Product should fit content, honoring max width rules.
      },
    },
    minWidth: "900px",
    "&.pricing-table": {
      backgroundColor: colors.white,
      tableLayout: "auto",
    },
    "&.pricing-table-disabled": {
      backgroundColor: defaultStyle.control.disabled.backgroundColor,
    },
  },
  "& .MuiSelect-select.MuiSelect-outlined, input.MuiInputBase-input.MuiOutlinedInput-input": {
    paddingTop: padding.xsmall,
    paddingBottom: padding.xsmall,
  },
  "& td div": {
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    // fontStyle: "normal",
    lineHeight: "150%",
    overflow: "hidden",
    paddingTop: padding.xsmall,
    textOverflow: "ellipsis",
  },
  "& thead th": {
    fontWeight: fontWeight.bold2,
  },
  "& tfoot td div": {
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold2,
    fontStyle: "normal",
    lineHeight: "140%",
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
  "& .pricing-table, .pricing-table-disabled": {
    "& .reissueEditIcon span": {
      color: `${colors.blue01} !important`
    },
    "& .reissueEditIcon.Mui-disabled span": {
      color: `${colors.blue11} !important`
    },
  },
  "& .pricing-table-disabled": {
    "& tbody td div, span": {
      color: `${colors.grey11} !important`
    },
    "& tfoot td div": {
      color: `${colors.grey11} !important`
    },
  },
});

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  fontWeight: fontWeight.bold2,
  overflow: "hidden",
  paddingRight: padding.large1,
  textOverFlow: "ellipses",
});

const StyledCheckboxInputField = styled(CheckboxInputField)({
  display: "none",
});

enum PolicyDialogTriggeredBy {
  ReIssueCheckbox = "ReIssueCheckbox",
  ReIssueIcon = "ReIssueIcon",
}

type PolicyDialogState = {
  open: boolean;
  index?: number;
  triggeredBy?: PolicyDialogTriggeredBy;
};

type TaxDialogState = {
  open: boolean;
  taxProductIndex?: number;
  isProductSource?: boolean;
  productIndex?: number;
  productItemIndex?: number;
  tax?: number;
};

const PRICING_CALCULATE_MESSAGE =
  "One or more items has changed that affects pricing. Please click the Calculate button below for updated pricing.";

const PRICING_GENERIC_ERROR_MESSAGE =
  "Pricing cannot be returned for one or more products. Please review errors below by hovering over the warning symbol(s).";

const PricingData = ({ onNavigate }: Props) => {
  const [{ isRevisingMode }] = useFilePage();
  const { setSimultaneousRate, resetValidationRequiredFlags, updateShowOPNFlags } = useCreateFile();
  const { isFileLocked, isReadOnly, reloadFile } = useCreateFile();
  const [{ isReadOnly: isReadOnlyByInactiveAgency }] = useFiles();
  const [{ initialValues }, { saveFile, resetPricingFields }] = useInitialValuesFiles();
  const [, { reportPricing, undoReportPricing }] = usePayment();
  const [, { calculatePricing }] = usePricingCalculatorContext();
  const [{ pricingConfigs }] = usePricingConfig();
  const [
    ,
    {
      setPricingRecalculateRequired,
      setPricingNotificationUpdated,
      setIsPricingInProgress,
      setIsPricingCalculated,
    },
  ] = usePricingActions();
  const [
    {
      isPricingRecalculateRequired,
      isPricingCalculated,
      pricingNotificationUpdated,
    },
  ] = usePricingStates();
  const [{ isPricingInProgress }] = usePricingInProgress();
  const [{ isIssuedProductsCountUpdated }] = useIssuedProductsCountUpdated();

  const {
    isFileLockedWithoutUpdateablePricingProducts,
    isFileLockedWithUpdateblePricingProducts,
    setPricingValidationRequiredFlags,
    showCalculateButton,
    getProductIndex
  } = usePricing();
  const [{ overrideReasonTypes }, { getOverrideReasonTypes, updateOverrideReasonTypes }] = useLookup();
  const [{ agencyStateRemittance }] = useCompanyRemittance();
  const [, { setBypassDefaultLoader }] = useProcessStatusTracking();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const [, { calculateItem }] = useOrder();
  const pricingTableContainerDisabled = useFieldDisabler(
    "PricingTableContainer"
  );

  const [openReportPricingDialog, setOpenReportPricingDialog] = useState(false);
  const [runReportPricingProgress, setRunReportPricingProgress] =
    useState(false);
  const [reportPricingRequestId, setReportPricingRequestId] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [isQAOpen, setIsQAOpen] = useState(false);
  const [reportPricingProgressBaseline, setReportPricingProgressBaseline] =
    useState(0);
  const [currentAction, setCurrentAction] = useState(ProductAction.None);
  const [reportPricingInProgress, setReportPricingInProgress] = useState(false);
  const [calculatePricingInProgress, setCalculatePricingInProgress] =
    useState(false);
  const [calculatePricingJustCompleted, setCalculatePricingJustCompleted] =
    useState(false);
  const [reportButtonDisabled, setReportButtonDisabled] = useState(true);
  const [calculateButtonDisabled, setCalculateButtonDisabled] = useState(true);
  const [
    calculateButtonEnabledBeforeOverride,
    setCalculateButtonEnabledBeforeOverride,
  ] = useState(false);
  const [resultErrorMessage, setResultErrorMessage] = useState<
    string | React.ReactNode
  >("");
  const [correlationId, setCorrelationId] = useState<string>("");
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [openReportAndPayConfirmationDialog, setOpenReportAndPayConfirmationDialog] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  const reportingResultRef = useRef<ReportingResult>({});

  let overrideReasonError = useRef<boolean>(false);
  let overrideReasonPrev = useRef<string>("");
  let overrideReasonInitialRef = useRef<string>("");
  let isTPSCalculated = useRef<boolean>(false);
  let isOverrideReasonTypeUpdated = useRef<boolean>(false);
  const [headerNotificationMessage, setHeaderNotificationMessage] = useState<
    string | undefined
  >();
  const [notificationType, setNotificationType] = useState(
    NotificationType.Error
  );
  const [pricingNotificationMessage, setPricingNotificationMessage] =
    useState<string | undefined>();
  const [pricingNotificationType, setPricingNotificationType] =
    useState(NotificationType.Error);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [policyDialogState, setPolicyDialogState] = useState<PolicyDialogState>(
    { open: false }
  );
  const [confirmationTaxDialog, setConfirmationTaxDialog] =
    useState<TaxDialogState>({
      open: false,
    });

  // track if the pricing amounts has been updated in Pricing section
  const [isPricingSectionUpdated, setIsPricingSectionUpdated] =
    useState<boolean>(false);

  // flash message
  const [, { showFlashMessage, closeFlashMessage }] = useFlashMessageActions();
  const {
    state: {
      hasMessageError,
      type,
      displayFieldIcon,
      items,
      products,
    },
    dispatch: pricingErrorMessageDispatch,
  } = usePricingErrorMessageContext();

  const hasRemainingPricingErrorMessage =
    [...items.values()].some((i) => i.displayFieldIcon) ||
    [...products.values()].some((i) => i.displayFieldIcon);

  let currentReportAction = useRef("");
  let currentActionResult = useRef<ActionResult>({});
  let isPricingInProgressRef = useRef<boolean>(false);

  const { getValues, setValue, trigger, clearErrors } = useFormWrapper();

  const isTPSFile: boolean = getValues("originationSource")?.includes("SC-TPS-API");
  const combinedQNAs = getValues("combinedQNAs");
  const pricingProducts = getValues("pricingProducts") as Array<PricingProduct>;
  const pricing = getValues("pricing") as Pricing;
  const noIssuedProducts = !pricingProducts.length;
  const isIntegratedPricing = getValues("pricing.isIntegratedPricing");

  const showTax = getValues("pricing.showTax"); //FROM API -> IsIntegratedPricing && State="KY"
  // const showCoInsurance =
  //   pricingConfigs?.find((c) => c.configKey === PricingConfigKey.CoInsurance)
  //     ?.configValue === "Allow";
  const isFileReadOnly = isReadOnly || isReadOnlyByInactiveAgency;

  const callStrafeForRemittance =
    // isIntegratedPricing &&
    pricingConfigs?.find(
      (c) => c.configKey === PricingConfigKey.CallStrafeForRemittance
    )?.configValue === "1";

  const hasFileLockedWithUpdateablePricingItem = isFileLockedWithUpdateblePricingProducts(
    isFileLocked,
    pricingProducts,
    isFileReadOnly
  );

  const {
    columns: {
      showLiabilityColumn,
      showRateTypeColumn,
      showReissueColumn,
      showRiskRateColumn,
      showTransCodeColumn,
    },
    showReissue,
  } = usePricingTable(pricingProducts);

  const [
    isOverride,
    overrideReason,
    overrideReasonInitial,
    isCalculationRequired,
    // errorMessage,
    // infoMessage,
    isUpdated,
    // isCoInsurance,
  ] = useWatch({
    name: [
      "pricing.isOverride",
      "pricing.overrideReason",
      "pricing.overrideReasonInitial",
      "pricing.isCalculationRequired",
      // "pricing.errorMessage",
      // "pricing.infoMessage",
      "pricing.isUpdated",
      // "pricing.isCoInsurance",
    ]
  });

  const resetPricingData = (event: any) => {
    setIsPricingCalculated(false);
    setIsPricingSectionUpdated(false);
  };

  const clearError = () => {
    currentActionResult.current = {};
  };

  const hasError = () => {
    // check bad request error ONLY for now
    if (currentActionResult.current.error) {
      return true;
    }
    return false;
  };

  const handleError = () => {
    // Check for any exception error
    if (
      currentActionResult &&
      currentActionResult.current.error &&
      currentActionResult.current.error.response &&
      currentActionResult.current.error.response.status !== 200
    ) {
      let handleErrorMessage = "";
      if (currentReportAction.current === ProductAction.UndoReport) {
        handleErrorMessage = UIConstants.UNDO_REPORT_ERROR_MESSAGE;
      } else {
        handleErrorMessage =
          currentActionResult.current.error.response.data &&
            currentActionResult.current.error.response.data.errorMessage
            ? currentActionResult.current.error.response.data.errorMessage
            : currentActionResult.current.error.response.statusText;
      }

      setResultErrorMessage(handleErrorMessage);
      setCorrelationId(getCorrelationId(currentActionResult.current.error.response.headers));
      setOpenErrorDialog(true);
      return;
    }
  };

  const handleOnYesErrorDialog = () => {
    setOpenErrorDialog(false);
    setResultErrorMessage("");
    setCorrelationId("");
  };

  const handleCompletedQnA = async () => {
    setReportPricingProgressBaseline(50);
    await handleReportPricing();
    setReportPricingProgressBaseline(0);
  };

  const handleCloseQnA = () => {
    setValue("combinedQNAs", []);
    setIsQAOpen(false);
    setTimeout(() => setBypassDefaultLoader(false), 1000);
  };

  const handleFlashMessageClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    closeFlashMessage();
  };

  const handleReportingComplete = (reportingResult: ReportingResult) => {
    if (reportingResult.pdfUrl) {
      showPaymentSheetPdf(reportingResult.pdfUrl);
    }
    else {
      reportingResultRef.current = reportingResult;
      setOpenReportAndPayConfirmationDialog(true);
    }
  };

  const showPaymentSheetPdf = (pdfUrl: string) => {
    let pdfs: PdfDocument[] = [];
    if (pdfUrl) {
      pdfs.push({
        fileId: 0,
        productType: "",
        orderId: 0,
        documentId: 0,
        pdfUrl: pdfUrl,
        fileName: `Payment Sheet ${pdfUrl.split("?")[0].split("/")?.pop() ?? ""}`,
        showHidden: 0,
      });
    }
    setPdfDocuments(pdfs);
    setOpenPdfViewer(pdfs.length > 0);
  };

  const flashMessageAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleFlashMessageClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const enableCalculate = (
    ignoreOverride: boolean = false,
    enableOnly: boolean = false
  ) => {
    if (!isIntegratedPricing) return; //Applicable only for IntegratedPricing
    if (pricingProducts?.length > 0 && (!isOverride || ignoreOverride)) {
      setCalculateButtonDisabled(false);
      setReportButtonDisabled(true);
      setCalculatePricingJustCompleted(false);

      // Don't display the Calculate message if there's a previous message
      if (
        !enableOnly &&
        // !errorMessage &&
        // !infoMessage &&
        showCalculateButton(isIntegratedPricing, isFileLocked, isFileReadOnly)   // rlo 10/12/2022 - don't calculate message when a file is locked and no any updateable items
      ) {
        setHeaderNotificationMessage(PRICING_CALCULATE_MESSAGE);
        setNotificationType(NotificationType.Error);
      }
    }
    setValue("pricing.isCalculationRequired", true);
    setPricingRecalculateRequired(false);
  };

  const disableCalculate = () => {
    if (!isIntegratedPricing) return; //Applicable only for IntegratedPricing
    setCalculateButtonDisabled(true);
    setHeaderNotificationMessage("");
    setValue("pricing.isCalculationRequired", false);
    setPricingRecalculateRequired(false);
  };

  const handleOnCalculate = async () => {
    // clear preivous action error
    clearError();

    // clear  previous validaiton errors and reset validation required flags
    clearErrors();
    resetValidationRequiredFlags();

    await runValidation({
      values: getValues() as SCFile,
      trigger,
      setValue,
      productAction: MapActionType.ProductAction,
    });

    setCalculatePricingInProgress(true);
    disableCalculate();
    setCurrentAction(ProductAction.CalculatePricing);

    currentReportAction.current = ProductAction.CalculatePricing;
    let receiptId = uuidv4();
    setReportPricingRequestId(receiptId);
    setStatusMessage("Please wait while we calculate updated pricing...");
    setBypassDefaultLoader(true);
    setOpenReportPricingDialog(true);
    setRunReportPricingProgress(true);

    const values: SCFile = getValues();

    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      values,
      undefined,
      MapActionType.ProductAction,
      ProductAction.CalculatePricing
    );

    // -- calling calculatePricing
    const submit = async () => {
      setPricingNotificationUpdated(false);
      //Need this clone because pricing products in values is changing
      //a result of calculate pricing.
      const stableValues = cloneDeep(values);
      currentActionResult.current = await calculatePricing(
        values,
        apiSCFile,
        receiptId,
        initialValues
      );

      const pendingFile: SCFile = stableValues;
      const calculatedFile = currentActionResult.current.scfile;

      // Calculate doesn't update database so have to set values manually in order to preserve isDirty state
      if (calculatedFile) {
        if (calculatedFile.pricing) {
          setValue("pricing.errorMessage", calculatedFile.pricing.errorMessage);
          setValue("pricing.infoMessage", calculatedFile.pricing.infoMessage);
          setValue("pricing.infoMessageFontColor", calculatedFile.pricing.infoMessageFontColor);
          setValue("pricing.infoMessageFontWeight", calculatedFile.pricing.infoMessageFontWeight);
          setValue(
            "pricing.isCalculationRequired",
            calculatedFile.pricing.isCalculationRequired
          );
          setValue(
            "pricing.isSimultaneousRate",
            calculatedFile.pricing.isSimultaneousRate
          );

          // Update ShowOPN Flags to refresh the OPN Dialog fields.
          updateShowOPNFlags(calculatedFile.pricing);
        }

        //Note to future Self:  This is what is causing the Reissue, etc. columns to vanish
        copyPricingProducts(
          calculatedFile.pricingProducts,
          pendingFile.pricingProducts
        );

        copyPricingDetails(
          pendingFile.jackets,
          calculatedFile.jackets,
          "jackets"
        );

        if (pendingFile.aALProducts && calculatedFile.aALProducts) {
          copyPricingDetails(
            pendingFile.aALProducts,
            calculatedFile.aALProducts,
            "aALProducts"
          );
        }
        copyPricingDetails(pendingFile.cpls, calculatedFile.cpls, "cpls");
        copyPricingDetails(
          pendingFile.standaloneEndorsements,
          calculatedFile.standaloneEndorsements,
          "standaloneEndorsements"
        );

        copyEndorsementsPricingDetails(pendingFile, calculatedFile);
      }

      // Set if actual fee is updated
      setIsPricingSectionUpdated(false);
      setActualFeeUpdated();
      setTimeout(() => {
        setIsPricingSectionUpdated(true);
      });
    };

    submit();

    setPricingNotificationUpdated(true);
    setCalculatePricingJustCompleted(true);

    //Enable Report button onCalculate only if the file has at least one Jacket issued
    const activeJackets = pricingProducts.find(
      (pp) => pp.productType === ProductType.Jacket
    );
    if (activeJackets) setReportButtonDisabled(false);

    setCalculatePricingInProgress(false);
  };

  const copyPricingDetails = (
    pendingProducts: SCOrder[],
    calculatedProducts: SCOrder[],
    productCollection: string
  ) => {
    calculatedProducts.forEach((product) => {      
      const matchedIndex = pendingProducts.findIndex(
        (p) => p.integrationKey === product.integrationKey
      );
      const matched =
        matchedIndex > -1 ? pendingProducts[matchedIndex] : undefined;
      if (matched) {
        if (!matched.pricingDetail) {
          matched.pricingDetail = product.pricingDetail;
        } else {          
          matched.pricingDetail.actualFee = product.pricingDetail?.actualFee;
          matched.pricingDetail.calculatedFee =
            product.pricingDetail?.calculatedFee;
          matched.pricingDetail.actualRemittance =
            product.pricingDetail?.actualRemittance;
          matched.pricingDetail.calculatedRemittance =
            product.pricingDetail?.calculatedRemittance;
          matched.pricingDetail.actualRiskRate =
            product.pricingDetail?.actualRiskRate;
          matched.pricingDetail.calculatedRiskRate =
            product.pricingDetail?.calculatedRiskRate;
          matched.pricingDetail.actualPremiumTax =
            product.pricingDetail?.actualPremiumTax;
          matched.pricingDetail.calculatedPremiumTax =
            product.pricingDetail?.calculatedPremiumTax;
          matched.pricingDetail.actualRetention =
            product.pricingDetail?.actualRetention;
          matched.pricingDetail.calculatedRetention =
            product.pricingDetail?.calculatedRetention;
          matched.pricingDetail.chargeType = product.pricingDetail?.chargeType;
          matched.pricingDetail.coverageType =
            product.pricingDetail?.coverageType;
          matched.pricingDetail.coverageTypeData =
            product.pricingDetail?.coverageTypeData;
          matched.pricingDetail.pricingRateType =
            product.pricingDetail?.pricingRateType;
          matched.pricingDetail.pricingRateTypeData =
            product.pricingDetail?.pricingRateTypeData;
          matched.pricingDetail.isReissue = product.pricingDetail?.isReissue;          
          matched.pricingDetail.altPricingReferenceID =
            product.pricingDetail?.altPricingReferenceID;
          matched.pricingDetail.lastErrorMessage =
            product.pricingDetail?.lastErrorMessage;
          matched.pricingDetail.isFeeError =
            product.pricingDetail?.isFeeError;
          matched.pricingDetail.isManualFee =
            product.pricingDetail?.isManualFee;          
        }

        setValue(
          getNameString(`${productCollection}.${matchedIndex}.pricingDetail`),
          matched.pricingDetail
        );
      }
    });
  };

  const copyEndorsementsPricingDetails = (
    pendingFile: SCFile,
    calculatedFile: SCFile
  ) => {
    const calculatedJackets = calculatedFile.jackets;
    const pendingJackets = pendingFile.jackets;

    calculatedJackets.forEach((calculatedJacket) => {
      const matchedPendingJacketIndex = pendingJackets.findIndex(
        (p) => p.integrationKey === calculatedJacket.integrationKey
      );
      const matchedPendingJacket =
        matchedPendingJacketIndex > -1
          ? pendingJackets[matchedPendingJacketIndex]
          : undefined;

      if (matchedPendingJacket) {
        matchedPendingJacket.endorsements?.forEach(
          (pendingEndo: Endorsement, index) => {
            const calculatedEndo = calculatedJacket.endorsements?.find(
              (ce) => ce.endorsementID === pendingEndo.endorsementID
            );
            if (calculatedEndo && pendingEndo)
              updateEndorsementPricingDetails(
                pendingEndo,
                calculatedEndo,
                index,
                `jackets.${matchedPendingJacketIndex}`
              );
          }
        );
      }
    });

    const calculatedSAProducts = calculatedFile.standaloneEndorsements;
    const pendingSAProducts = pendingFile.standaloneEndorsements;

    calculatedSAProducts.forEach((calculatedSAProduct) => {
      const matchedPendingSAIndex = pendingSAProducts.findIndex(
        (p) => p.integrationKey === calculatedSAProduct.integrationKey
      );
      const matchedPendingSAProduct =
        matchedPendingSAIndex > -1
          ? pendingSAProducts[matchedPendingSAIndex]
          : undefined;

      if (matchedPendingSAProduct) {
        matchedPendingSAProduct.endorsements?.forEach(
          (pendingSAEndo: Endorsement, index) => {
            const calculatedSAEndo = calculatedSAProduct.endorsements?.find(
              (ce) => ce.endorsementID === pendingSAEndo.endorsementID
            );
            if (calculatedSAEndo && pendingSAEndo)
              updateEndorsementPricingDetails(
                pendingSAEndo,
                calculatedSAEndo,
                index,
                `standaloneEndorsements.${matchedPendingSAIndex}`
              );
          }
        );
      }
    });
  };

  const updateEndorsementPricingDetails = (
    matched: Endorsement,
    product: Endorsement,
    matchedIndex: number,
    productCollection: string
  ) => {
    if (matched) {
      if (!matched.pricingDetail) {
        matched.pricingDetail = product.pricingDetail;
      } else {
        matched.pricingDetail.actualFee = product.pricingDetail?.actualFee;
        matched.pricingDetail.calculatedFee =
          product.pricingDetail?.calculatedFee;
        matched.pricingDetail.actualRemittance =
          product.pricingDetail?.actualRemittance;
        matched.pricingDetail.calculatedRemittance =
          product.pricingDetail?.calculatedRemittance;
        matched.pricingDetail.actualRiskRate =
          product.pricingDetail?.actualRiskRate;
        matched.pricingDetail.calculatedRiskRate =
          product.pricingDetail?.calculatedRiskRate;
        matched.pricingDetail.actualPremiumTax =
          product.pricingDetail?.actualPremiumTax;
        matched.pricingDetail.calculatedPremiumTax =
          product.pricingDetail?.calculatedPremiumTax;
        matched.pricingDetail.actualRetention =
          product.pricingDetail?.actualRetention;
        matched.pricingDetail.calculatedRetention =
          product.pricingDetail?.calculatedRetention;
        matched.pricingDetail.chargeType = product.pricingDetail?.chargeType;
        matched.pricingDetail.coverageType =
          product.pricingDetail?.coverageType;
        matched.pricingDetail.coverageTypeData =
          product.pricingDetail?.coverageTypeData;
        matched.pricingDetail.pricingRateType =
          product.pricingDetail?.pricingRateType;
        matched.pricingDetail.pricingRateTypeData =
          product.pricingDetail?.pricingRateTypeData;
        matched.pricingDetail.altPricingReferenceID =
          product.pricingDetail?.altPricingReferenceID;
        matched.pricingDetail.lastErrorMessage =
          product.pricingDetail?.lastErrorMessage;
        matched.pricingDetail.isFeeError =
          product.pricingDetail?.isFeeError;
        matched.pricingDetail.isManualFee =
          product.pricingDetail?.isManualFee;
      }

      setValue(
        getNameString(
          `${productCollection}.endorsements.${matchedIndex}.pricingDetail`
        ),
        matched.pricingDetail
      );
    }
  };

  const copyPricingProducts = (
    calculatedPricingProducts: PricingProduct[],
    pendingPricingProducts: PricingProduct[]
  ) => {
    calculatedPricingProducts?.forEach((pp) => {
      const matchingIndex = pendingPricingProducts?.findIndex(
        (a) =>
          a.pricingType === pp.pricingType &&
          a.filePricingDetailId === pp.filePricingDetailId
      );

      if (matchingIndex >= 0) {
        try {
          setValue(getNameString(`pricingProducts.${matchingIndex}`), pp);
        } catch (e) {
          console.error("CopyPricingProducts: ", e);
        }
      }
    });
  };

  const submitReportPricing = async () => {
    // clear preivous action error
    clearError();

    // clear previous validaiton errors and reset validation required flags
    clearErrors();
    resetValidationRequiredFlags();

    // set pricing validation required flags
    setPricingValidationRequiredFlags();

    const isFormValid = await runValidation({
      values: getValues() as SCFile,
      trigger,
      setValue,
      productAction: MapActionType.ProductAction,
    });

    if (!isFormValid) {
      setDOMReportButton(false);
      return;
    }

    // Set ReportOptionTypeCode
    setValue("pricing.reportOptionTypeCode", ReportType.ReportOnly);

    setCalculatePricingJustCompleted(true);
    setReportPricingInProgress(true);
    // setReportButtonDisabled(true);
    setCurrentAction(ProductAction.ReportPricing);

    currentReportAction.current = ProductAction.ReportPricing;
    const requestId = uuidv4();
    const fileId = getValues("id");

    setReportPricingRequestId(requestId);
    setStatusMessage("Please wait while reporting your premiums...");
    setBypassDefaultLoader(true);
    setOpenReportPricingDialog(true);
    setRunReportPricingProgress(true);
    setPricingNotificationUpdated(false);

    const formValues: SCFile = getValues();

    const fileToSave = mapUiSCFileToApiSCFile(
      initialValues,
      formValues,
      undefined,
      MapActionType.SaveFile
    );

    await saveFile(fileToSave, false);

    const reportAndPayCriteria: ReportAndPayCriteria = {
      PendingPaymentItems: [
        {
          FileID: fileId,
        },
      ]
    }
    
    reportingResultRef.current = await reportPricing(reportAndPayCriteria, requestId, ReportAndPayAction.ReportOnly);

    setReportPricingInProgress(false);
    setCalculatePricingJustCompleted(false);
    setPricingNotificationUpdated(true);
    pricingErrorMessageDispatch({ type: "RESET" });

    setOpenReportAndPayConfirmationDialog(true);
  };

  const setDOMReportButton = (disable: boolean) => {
    //Later the query will be updated to include all Report buttons
    const querySelector = disable
      ? "button[name='Report']:not([disabled]"
      : "button[name='Report']";
    document.querySelectorAll(querySelector)?.forEach((e: any) => {
      e.disabled = disable;
      disable
        ? e.classList.add("Mui-disabled")
        : e.classList.remove("Mui-disabled");
    });
    if (!disable) setReportButtonDisabled(false);
  };

  const handleReportPricing = () => {
    //Disable Report Button
    setDOMReportButton(true);

    // Wait until Pricing is progress in complete.
    const timerStart = Date.now();
    const interval = setInterval(() => {
      const ellapsedMiliseconds = Date.now() - timerStart;
      // rlo 5/20/2022 - must use isPricingInProgressRef useRef variable within setInterval, because fileState is not updated within setInterval
      if (!isPricingInProgressRef.current) {
        submitReportPricing();
        clearInterval(interval);
        return;
      }

      if (
        ellapsedMiliseconds >
        UIConstants.WAITING_PROCESS_IN_PROGRESS_MAX_MILISECONDS
      ) {
        clearInterval(interval);
      }
    }, UIConstants.CHECK_PROCESS_IN_PROGRESS_INTERVAL_MILISECONDS);
  };

  const handleUndoReport = async () => {
    // clear preivous action error
    clearError();

    // clear previous validation errors and reset validateion required flags
    clearErrors();
    resetValidationRequiredFlags();

    currentReportAction.current = ProductAction.UndoReport;

    const requestId = uuidv4();
    const fileId = getValues("id");
    setReportPricingRequestId(requestId);
    setStatusMessage(UIConstants.UNDO_REPORT_REQUEST_SUBMITTING_MESSAGE);
    setBypassDefaultLoader(true);
    setOpenReportPricingDialog(true);
    setRunReportPricingProgress(true);

    currentActionResult.current = await undoReportPricing([fileId], requestId);
    setReportPricingInProgress(false);
    pricingErrorMessageDispatch({ type: "RESET" });
  };

  const handleCloseReportPricingDialog = async () => {
    setRunReportPricingProgress(false);
    setOpenReportPricingDialog(false);
    setReportPricingRequestId("");
    setTimeout(() => setBypassDefaultLoader(false), 1000);

    // check for error
    if (!hasError()) {
      const productActions = [ProductAction.UndoReport.toString(), ProductAction.ReportPricing.toString()];
      if (productActions.includes(currentReportAction.current)) {
        openAutomaticProgressDialog();
        await reloadFile();
        closeAutomaticProgressDialog();
      }
    } else {
      handleError();
      // reset report button
      setReportButton();
    }
  };

  const getCalculatedRemittance = async (
    premiumAmount: number,
    sourcePricingType: PricingType,
    productIndex: number,
    productItemIndex: number = -1
  ): Promise<number | undefined> => {
    const pricingProducts: PricingProduct[] = getValues("pricingProducts");
    const { productType, pricingType } = pricingProducts[productIndex];
    const isProductSource = sourcePricingType === PricingType.Product;

    // Other than Product, set Remitance (Total Due) = Premium
    // if (pricingType === PricingType.Tax) return premiumAmount;
    if (pricingType !== PricingType.Product) return premiumAmount; 

    if (callStrafeForRemittance) {
      const remittance = await calculateRemittanceFromStrafe(
        productIndex,
        productItemIndex,
        isProductSource,
        productType,
        premiumAmount
      );

      return remittance;
    }

    const { liability } = pricingProducts[productIndex];
    const itemType = isProductSource ? productType : ProductType.Endorsement;
    return calculateRemittanceManually(
      isProductSource,
      itemType,
      liability,
      premiumAmount
    );
  };

  const calculateRemittanceFromStrafe = async (
    productIndex: number,
    productItemIndex: number,
    isProductSource: boolean,
    productType: string,
    premium: number
  ): Promise<number> => {
    openAutomaticProgressDialog();

    const formValues = getValues() as SCFile;
    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      formValues,
      undefined,
      MapActionType.ProductAction,
      ProductAction.CalculateItem
    );
    const { filePricingDetailId: productFilePricingDetailId } =
      formValues.pricingProducts[productIndex];
    const { orderID, productEffectiveDate } =
      formValues.filePricingDetails.filter(
        (detail) =>
          detail.id ===
          (isProductSource
            ? productFilePricingDetailId
            : formValues.pricingProducts[productIndex].productItems[
              productItemIndex
            ].filePricingDetailId)
      )[0];
    const taxProductIndex = formValues.pricingProducts.findIndex(
      (product) =>
        product.filePricingDetailId === productFilePricingDetailId &&
        product.pricingType === PricingType.Tax
    );

    const response = await calculateItem(
      apiSCFile,
      orderID,
      isProductSource ? productType : ProductType.Endorsement,
      productEffectiveDate,
      premium
    );

    if (
      taxProductIndex > -1 &&
      formValues.pricingProducts[taxProductIndex].actualFeeUpdated
    ) {
      setConfirmationTaxDialog({
        open: true,
        taxProductIndex,
        isProductSource,
        productIndex,
        productItemIndex,
        tax: response.SalesTax,
      });
    } else {
      updateProductPremiumTax(
        isProductSource,
        taxProductIndex,
        productIndex,
        productItemIndex,
        convertToNumber(response.SalesTax)
      );
    }

    closeAutomaticProgressDialog();
    return convertToNumber(response.Remittance);
  };

  const handleAcceptTaxAmountStrafe = () => {
    const {
      taxProductIndex,
      isProductSource,
      productIndex,
      productItemIndex,
      tax,
    } = confirmationTaxDialog;

    if (
      isProductSource === undefined ||
      taxProductIndex === undefined ||
      productIndex === undefined ||
      productItemIndex === undefined ||
      tax === undefined
    )
      throw new Error(
        "Confirmation Tax Dialog cannot read `undefined` params."
      );

    updateProductPremiumTax(
      isProductSource,
      taxProductIndex,
      productIndex,
      productItemIndex,
      convertToNumber(tax)
    );

    setConfirmationTaxDialog({ open: false });
    setValue(
      getNameString(`pricingProducts.${taxProductIndex}.actualFeeUpdated`),
      false
    );
    setTimeout(() => {
      setIsPricingSectionUpdated(true);
    });
  };

  const handleDeclineTaxAmountStrafe = () =>
    setConfirmationTaxDialog({ open: false });

  const updateTaxTotalProductPremium = (productIndex: number) => {
    const currentPricingProducts: PricingProduct[] =
      getValues("pricingProducts");
    const currentProduct: PricingProduct = getValues(
      getNameString(`pricingProducts.${productIndex}`)
    );

    const taxIndex = currentPricingProducts?.findIndex(
      (product) =>
        product.filePricingDetailId === currentProduct?.filePricingDetailId &&
        product.pricingType === PricingType.Tax
    );
    if (taxIndex >= 0) {
      const { actualFeeSum } = getSumPricingTaxes(currentProduct, isFileLocked);
      setValue(
        getNameString(`pricingProducts.${taxIndex}.totalProductPremium`),
        actualFeeSum
      );
    }
  };

  const updateProductPremiumTax = (
    isProductSource: boolean,
    productTaxIndex: number,
    productIndex: number,
    productItemIndex: number,
    tax: number
  ) => {
    if (isProductSource) {
      setValue(
        getNameString(`pricingProducts.${productIndex}.actualPremiumTax`),
        tax
      );
    } else {
      setValue(
        getNameString(
          `pricingProducts.${productIndex}.productItems.${productItemIndex}.actualPremiumTax`
        ),
        tax
      );
    }

    if (productTaxIndex > -1) {
      const { actualPremiumTaxSum } = getSumPricingTaxes(
        getValues(getNameString(`pricingProducts.${productIndex}`)),
        isFileLocked
      );

      setValue(
        getNameString(`pricingProducts.${productTaxIndex}.actualFee`),
        actualPremiumTaxSum
      );

      setValue(
        getNameString(`pricingProducts.${productTaxIndex}.actualFeeInitial`),
        actualPremiumTaxSum
      );

      setValue(
        getNameString(`pricingProducts.${productTaxIndex}.totalDue`),
        actualPremiumTaxSum
      );

      setValue(
        getNameString(`pricingProducts.${productTaxIndex}.totalDueInitial`),
        actualPremiumTaxSum
      );
    }
  };

  const calculateRemittanceManually = (
    isProductSource: boolean,
    productType: string,
    liability: number,
    premium: number
  ) => {
    const remitSplitType = isProductSource
      ? agencyStateRemittance?.remitSplitType
      : agencyStateRemittance?.stdEndorsementSplitType;
    const remitFlatPct = isProductSource
      ? agencyStateRemittance?.remitFlatPct
      : agencyStateRemittance?.stdEndorsementRemitPct;
    const remitPerThousand = isProductSource
      ? agencyStateRemittance?.remitPerThousand
      : agencyStateRemittance?.stdEndorsementRemitPerThousand;

    const remittance = calculateRemittance(
      productType,
      convertToNumber(liability),
      premium,
      remitSplitType,
      convertToNumber(remitFlatPct),
      convertToNumber(remitPerThousand),
      convertToNumber(agencyStateRemittance?.minRemitPerThousand),
      convertToNumber(agencyStateRemittance?.cplRemittancePct)
    );

    return remittance;
  };

  const validateAndProcessAgentRetention = (
    productName: string,
    actualRiskRate: number,
    actualFee: number,
    agentRetention: number,
    oldAgentRetention: number,
  ) => {
    let agencyRetentionIsValid = true;
    let totalDueIsValid = true;

    const totalDue =
      showRiskRateColumn
        ? actualRiskRate - agentRetention
        : actualFee - agentRetention;

    if (showRiskRateColumn) {
      if (actualRiskRate >= 0) {
        agencyRetentionIsValid = agentRetention <= actualRiskRate;
        totalDueIsValid = totalDue <= actualRiskRate;
      }
      else {
        agencyRetentionIsValid = agentRetention >= actualRiskRate;
        totalDueIsValid = totalDue >= actualRiskRate;
      }
    }
    else {
      if (actualFee >= 0) {
        agencyRetentionIsValid = agentRetention <= actualFee;
        totalDueIsValid = totalDue <= actualFee;
      }
      else {
        agencyRetentionIsValid = agentRetention >= actualFee;
        totalDueIsValid = totalDue >= actualFee;
      }
    }

    if (agencyRetentionIsValid && totalDueIsValid) {
      setIsPricingSectionUpdated(false);
      setValue(
        getNameString(`${productName}.agentRetentionInitial`),
        agentRetention
      );
      setValue(getNameString(`${productName}.actualFeeUpdated`), true);
      setTimeout(() => {
        setIsPricingSectionUpdated(true);
      });

      setValue(getNameString(`${productName}.totalDue`), totalDue, {
        shouldDirty: true,
      });
      setValue(getNameString(`${productName}.totalDueInitial`), totalDue, {
        shouldDirty: true,
      });
    }
    else {
      setValue(
        getNameString(`${productName}.agentRetention`),
        oldAgentRetention,
        { shouldDirty: true }
      );

      let msg = "";
      if (!agencyRetentionIsValid) {
        if (showRiskRateColumn) {
          msg = `Agent Retention of ${formatCurrency(agentRetention)} exceeded Risk Rate. Please enter a different amount.`;
        }
        else {
          msg = `Agent Retention of ${formatCurrency(agentRetention)} exceeded Premium/Fee. Please enter a different amount.`;
        }
      }
      else if (!totalDueIsValid) {
        if (showRiskRateColumn) {
          msg = `Total Due of ${formatCurrency(totalDue)} exceeded Risk Rate. Please enter a different amount.`;
        }
        else {
          msg = `Total Due of ${formatCurrency(totalDue)} exceeded Premium/Fee. Please enter a different amount.`;
        }
      }

      showFlashMessage(msg);
    }
  };

  const validateAndProcessTotalDue = (
    productName: string,
    actualRiskRate: number,
    actualFee: number,
    totalDue: number,
    oldTotalDue: number,
  ) => {
    let totalDueIsValid = true;
    let agentRetentionIsValid = true;

    const agentRetention = showRiskRateColumn
      ? actualRiskRate - totalDue
      : actualFee - totalDue;

    if (showRiskRateColumn) {
      if (actualRiskRate >= 0) {
        totalDueIsValid = totalDue <= actualRiskRate;
        agentRetentionIsValid = agentRetention <= actualRiskRate;
      }
      else {
        totalDueIsValid = totalDue >= actualRiskRate;
        agentRetentionIsValid = agentRetention >= actualRiskRate;
      }
    }
    else {
      if (actualFee >= 0) {
        totalDueIsValid = totalDue <= actualFee;
        agentRetentionIsValid = agentRetention <= actualFee;
      }
      else {
        totalDueIsValid = totalDue >= actualFee;
        agentRetentionIsValid = agentRetention >= actualFee;
      }
    }

    if (totalDueIsValid && agentRetentionIsValid) {
      setIsPricingSectionUpdated(false);
      setValue(getNameString(`${productName}.totalDueInitial`), totalDue);
      setValue(getNameString(`${productName}.actualFeeUpdated`), true);
      setTimeout(() => {
        setIsPricingSectionUpdated(true);
      });

      setValue(getNameString(`${productName}.agentRetention`),
        agentRetention, { shouldDirty: true, });
      setValue(getNameString(`${productName}.agentRetentionInitial`),
        agentRetention,
        { shouldDirty: true }
      );
    }
    else {
      setValue(getNameString(`${productName}.totalDue`), oldTotalDue, {
        shouldDirty: true,
      });

      let msg = "";
      if (!totalDueIsValid) {
        if (showRiskRateColumn) {
          msg = `Total Due of ${formatCurrency(totalDue)} exceeded Risk Rate. Please enter a different amount.`;
        }
        else {
          msg = `Total Due of ${formatCurrency(totalDue)} exceeded Premium/Fee. Please enter a different amount.`;
        }
      }
      else if (!agentRetentionIsValid) {
        if (showRiskRateColumn) {
          msg = `Agent Retention of ${formatCurrency(agentRetention)} exceeded Risk Rate. Please enter a different amount.`;
        }
        else {
          msg = `Agent Retention of ${formatCurrency(agentRetention)} exceeded Premium/Fee. Please enter a different amount.`;
        }
      }

      showFlashMessage(msg);
    }
  };

  const validateRiskRate = (riskRate: number, actualFee: number) => {
    let isValid = true;
    // Allow Negative Risk Rate per Defect #152407
    // if (riskRate < 0) {
    //   isValid = false;
    //   showFlashMessage(`Risk Rate cannot be negative. Please enter a different amount.`);
    // }
    if (
      (actualFee >= 0 && actualFee < riskRate) ||
      (actualFee < 0 &&
        ((riskRate >= 0 && actualFee < riskRate) ||
          (riskRate < 0 && actualFee > riskRate)))
    ) {
      isValid = false;
      showFlashMessage(`Risk Rate of ${formatCurrency(riskRate)} exceeded Premium/Fee. Please enter a different amount.`);
    }

    return isValid;
  };


  const validateTaxAmount = (taxAmount: number, totalProductPremium: number): boolean => {
    let isValid = true;
    // ONLY not allow negative Tax amount when Product Actual Fee is positive
    if (totalProductPremium >= 0 && taxAmount < 0) {
      isValid = false;
      showFlashMessage("Tax amounts cannot be negative. Please enter a different amount.");
    }

    return isValid;
  };

  const validateActualFeeIsGreaterThanRiskRate = (
    actualFee: number,
    riskRate: number
  ): boolean => {
    let isValid = true;
    if (
      showRiskRateColumn &&
      (actualFee >= 0 && actualFee < riskRate)
    ) {
      isValid = false;
      showFlashMessage(
        `Premium/Fee of ${formatCurrency(
          actualFee
        )} is less than Risk Rate. Please enter a different amount.`
      );
    }

    return isValid;
  };

  // Product
  const handleActualFeeBlur = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const productName = `pricingProducts.${index}`;
    const actualFee = convertToNumber(e.target.value);
    const oldActualFee = convertToNumber(
      pricingProducts[index].actualFeeInitial
    );
    const riskRate = convertToNumber(getValues(productName + '.actualRiskRate'));

    if (pricingProducts[index].pricingType === PricingType.Tax) {
      const totalProductPremium = convertToNumber(pricingProducts[index].totalProductPremium);
      const taxAmount = actualFee; // actualFee for Tax pricing type is a tax amount
      const isValid = validateTaxAmount(taxAmount, totalProductPremium);
      if (!isValid) {
        setValue(getNameString(`${productName}.actualFee`), oldActualFee, {
          shouldDirty: true,
        });

        return;
      }
    }

    const isValidActualFee = validateActualFeeIsGreaterThanRiskRate(actualFee, riskRate);

    if (!isValidActualFee) {
      setValue(getNameString(`${productName}.actualFee`), oldActualFee, {
        shouldDirty: true,
      });

      return;
    }

    if (actualFee !== oldActualFee) {
      // Update File Context Provider  that the pricing process is in progress
      isPricingInProgressRef.current = true;
      setIsPricingInProgress(true);

      if (
        // isIntegratedPricing &&
        pricingProducts[index].pricingType === PricingType.Product
      ) {
        updateTaxTotalProductPremium(index);
      }
      if (
        // Perform tax validation for integrated/manual to match same logic as callStrafeForRemittance().
        // isIntegratedPricing &&
        pricingProducts[index].pricingType === PricingType.Tax
      ) {
        const totalProductPremium = convertToNumber(
          pricingProducts[index].totalProductPremium
        );

        const isValid =
          (actualFee >= 0 && actualFee <= totalProductPremium) ||
          (actualFee < 0 && actualFee > totalProductPremium);

        if (!isValid) {
          setValue(getNameString(`${productName}.actualFee`), oldActualFee, {
            shouldDirty: true,
          });
          showFlashMessage(`Tax Amount of ${formatCurrency(actualFee)} exceeded Product Premiums. Please enter a different amount.`);
          return;
        }
      }
      setIsPricingSectionUpdated(false);

      setValue(getNameString(`${productName}.actualFeeInitial`), actualFee);
      // if (!isIntegratedPricing) {
      //   setValue(getNameString(`${productName}.calculatedFee`), actualFee);
      // }

      setValue(getNameString(`${productName}.actualFeeUpdated`), true);
      setTimeout(() => {
        setIsPricingSectionUpdated(true);
      });

      // calculate remittance
      if (!showRiskRateColumn) {
        const remittance = await getCalculatedRemittance(
          actualFee,
          PricingType.Product,
          index
        );

        let totalDue = 0;
        if (hasValue(remittance)) {
          totalDue = remittance || 0;
        }

        setValue(getNameString(`${productName}.totalDue`), totalDue, {
          shouldDirty: true,
        });

        setValue(getNameString(`${productName}.totalDueInitial`), totalDue, {
          shouldDirty: true,
        });

        // if (!isIntegratedPricing) {
        //   setValue(
        //     getNameString(`${productName}.calculatedTotalDue`),
        //     totalDue
        //   );
        // }

        let agentRetention = actualFee - totalDue;

        setValue(
          getNameString(`${productName}.agentRetention`),
          agentRetention,
          {
            shouldDirty: true,
          }
        );
        setValue(
          getNameString(`${productName}.agentRetentionInitial`),
          agentRetention,
          { shouldDirty: true }
        );

        // rlo 10/4/2022 - will leave the commented code here for now -- not sure why extra logic needed from actualRiskRate,
        // but actualRiskRate for Non-RiskRate state will be updated in backend.
        // Also the trigger validation is not necessary, because RiskRate colum is hidden

        // if (isIntegratedPricing) {
        //   if (!isOverride) {
        //     setValue(
        //       getNameString(`${productName}.calculatedRetention`),
        //       agentRetention
        //     );
        //   } else {
        //     if (actualFee === 0) {
        //       setValue(
        //         getNameString(`${productName}.actualRiskRate`),
        //         actualFee
        //       );
        //     } else trigger(getNameString(`${productName}.actualRiskRate`));
        //   }
        // } else {
        //   setValue(
        //     getNameString(`${productName}.calculatedRetention`),
        //     agentRetention
        //   );
        // }
        // if (!isIntegratedPricing) {
        //   setValue(
        //     getNameString(`${productName}.calculatedRetention`),
        //     agentRetention
        //   );
        // }
      } else {
        // Zeroing all amounts field when premium is 0
        if (actualFee === 0) {
          setValue(getNameString(`${productName}.totalDue`), 0, {
            shouldDirty: true,
          });

          setValue(getNameString(`${productName}.totalDueInitial`), 0, {
            shouldDirty: true,
          });

          setValue(getNameString(`${productName}.agentRetention`), 0, {
            shouldDirty: true,
          });

          setValue(getNameString(`${productName}.agentRetentionInitial`), 0, {
            shouldDirty: true,
          });

          if (isIntegratedPricing) {
            setValue(getNameString(`${productName}.actualRiskRate`), 0);
            setValue(getNameString(`${productName}.actualRiskRateInitial`), 0);
          } else {
            setValue(getNameString(`${productName}.calculatedTotalDue`), 0);
            setValue(getNameString(`${productName}.calculatedRetention`), 0);
          }
        }
      }

      isPricingInProgressRef.current = false;
      setIsPricingInProgress(false);
    }
  };

  const handleAgentRetentionBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const agentRetention = convertToNumber(e.target.value);
    const oldAgentRetention = convertToNumber(
      pricingProducts[index].agentRetentionInitial
    );

    if (agentRetention !== oldAgentRetention) {
      const productName = `pricingProducts.${index}`;
      const actualFee = convertToNumber(pricingProducts[index].actualFee);
      const actualRiskRate = convertToNumber(pricingProducts[index].actualRiskRate);

      validateAndProcessAgentRetention(
        productName,
        actualRiskRate,
        actualFee,
        agentRetention,
        oldAgentRetention,
      );
    }
  };

  const handleTotalDueBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const totalDue = convertToNumber(e.target.value);
    const oldTotalDue = convertToNumber(pricingProducts[index].totalDueInitial);

    if (totalDue !== oldTotalDue) {
      const productName = `pricingProducts.${index}`;
      const actualFee = convertToNumber(pricingProducts[index].actualFee);
      const actualRiskRate = convertToNumber(pricingProducts[index].actualRiskRate);

      validateAndProcessTotalDue(
        productName,
        actualRiskRate,
        actualFee,
        totalDue,
        oldTotalDue,
      );
    }
  };

  const handleRiskRateBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const pricingType: string = pricingProducts[index].pricingType;
    let riskRate = convertToNumber(e.target.value);
    let oldRiskRate = convertToNumber(
      pricingProducts[index].actualRiskRateInitial
    );

    const productName = `pricingProducts.${index}`;
    const actualFee = convertToNumber(getValues(productName + '.actualFee'));
    const isValid = validateRiskRate(riskRate, actualFee);

    if (!isValid) {
      setValue(getNameString(`${productName}.actualRiskRate`), oldRiskRate, {
        shouldDirty: true,
      });

      return;
    }

    if (riskRate !== oldRiskRate) {

      setValue(getNameString(`${productName}.actualRiskRateInitial`), riskRate);
      // if (!isIntegratedPricing) {
      //   setValue(getNameString(`${productName}.calculatedRiskRate`), riskRate);
      // }
      setIsPricingSectionUpdated(false);
      setValue(getNameString(`${productName}.actualFeeUpdated`), true);
      setTimeout(() => {
        setIsPricingSectionUpdated(true);
      });

      // calculate remittance
      if (showRiskRateColumn) {
        let remittance = calculateRemittance(
          pricingProducts[index].productType,
          convertToNumber(pricingProducts[index].liability),
          riskRate,
          agencyStateRemittance?.remitSplitType,
          convertToNumber(agencyStateRemittance?.remitFlatPct),
          convertToNumber(agencyStateRemittance?.remitPerThousand),
          convertToNumber(agencyStateRemittance?.minRemitPerThousand),
          convertToNumber(agencyStateRemittance?.cplRemittancePct),
          pricingType
        );

        let totalDue = 0;
        if (hasValue(remittance)) {
          totalDue = remittance || 0;
        }

        setValue(getNameString(`${productName}.totalDue`), totalDue, {
          shouldDirty: true,
        });

        setValue(getNameString(`${productName}.totalDueInitial`), totalDue, {
          shouldDirty: true,
        });

        // if (!isIntegratedPricing) {
        //   setValue(
        //     getNameString(`${productName}.calculatedTotalDue`),
        //     totalDue
        //   );
        // }

        let agentRetention = riskRate - totalDue;

        setValue(
          getNameString(`${productName}.agentRetention`),
          agentRetention,
          {
            shouldDirty: true,
          }
        );
        setValue(
          getNameString(`${productName}.agentRetentionInitial`),
          agentRetention,
          { shouldDirty: true }
        );

        // if (!isIntegratedPricing) {
        //   setValue(
        //     getNameString(`${productName}.calculatedRetention`),
        //     agentRetention
        //   );
        // }
      }
    }
  };

  // Product Item
  const handleActualFeeItemBlur = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    itemIndex: number
  ) => {
    let actualFee = convertToNumber(e.target.value);
    let oldActualFee = convertToNumber(
      pricingProducts[index].productItems[itemIndex].actualFeeInitial
    );
    // const isFeeError = convertToNumber(pricingProducts[index].productItems[itemIndex].isFeeError);
    const productName = `pricingProducts.${index}.productItems.${itemIndex}`;
    const riskRate = convertToNumber(getValues(productName + '.actualRiskRate'));

    const isValidActualFee = validateActualFeeIsGreaterThanRiskRate(actualFee, riskRate);

    if (!isValidActualFee) {
      setValue(getNameString(`${productName}.actualFee`), oldActualFee, {
        shouldDirty: true,
      });

      return;
    }

    if (actualFee !== oldActualFee) {
      setIsPricingInProgress(true);

      if (
        // isIntegratedPricing &&
        pricingProducts[index].pricingType === PricingType.Product
      ) {
        updateTaxTotalProductPremium(index);
      }

      setValue(getNameString(`${productName}.actualFeeInitial`), actualFee);
      // if (!isIntegratedPricing) {
      //   setValue(getNameString(`${productName}.calculatedFee`), actualFee);
      // }

      setIsPricingSectionUpdated(false);
      setValue(getNameString(`${productName}.actualFeeUpdated`), true);
      setTimeout(() => {
        setIsPricingSectionUpdated(true);
      });

      // calculate remittance
      if (!showRiskRateColumn) {
        const remittance = await getCalculatedRemittance(
          actualFee,
          PricingType.ProductItem,
          index,
          itemIndex
        );

        let totalDue = 0;
        if (hasValue(remittance)) {
          totalDue = remittance || 0;
        }

        setValue(getNameString(`${productName}.totalDue`), totalDue, {
          shouldDirty: true,
        });
        setValue(getNameString(`${productName}.totalDueInitial`), totalDue, {
          shouldDirty: true,
        });
        // if (!isIntegratedPricing) {
        //   setValue(
        //     getNameString(`${productName}.calculatedTotalDue`),
        //     totalDue
        //   );
        // }

        let agentRetention = actualFee - totalDue;

        setValue(
          getNameString(`${productName}.agentRetention`),
          agentRetention,
          {
            shouldDirty: true,
          }
        );
        setValue(
          getNameString(`${productName}.agentRetentionInitial`),
          agentRetention,
          { shouldDirty: true }
        );

        // if (!isIntegratedPricing) {
        //   setValue(
        //     getNameString(`${productName}.calculatedRetention`),
        //     agentRetention
        //   );
        // }

      } else {
        // Zeroing all amounts field when premium is 0
        if (actualFee === 0) {
          setValue(getNameString(`${productName}.totalDue`), 0, {
            shouldDirty: true,
          });

          setValue(getNameString(`${productName}.totalDueInitial`), 0, {
            shouldDirty: true,
          });

          setValue(getNameString(`${productName}.agentRetention`), 0, {
            shouldDirty: true,
          });

          setValue(getNameString(`${productName}.agentRetentionInitial`), 0, {
            shouldDirty: true,
          });

          if (isIntegratedPricing) {
            setValue(getNameString(`${productName}.actualRiskRate`), 0);
            setValue(getNameString(`${productName}.actualRiskRateInitial`), 0);
          } else {
            setValue(getNameString(`${productName}.calculatedTotalDue`), 0);
            setValue(getNameString(`${productName}.calculatedRetention`), 0);
          }
        }
      }

      setIsPricingInProgress(false);
    }
  };

  const handleAgentRetentionItemBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    itemIndex: number
  ) => {
    const agentRetention = convertToNumber(e.target.value);
    const oldAgentRetention = convertToNumber(
      pricingProducts[index].productItems[itemIndex].agentRetentionInitial
    );

    if (agentRetention !== oldAgentRetention) {
      const productName = `pricingProducts.${index}.productItems.${itemIndex}`;
      const actualFee = convertToNumber(
        pricingProducts[index].productItems[itemIndex].actualFee
      );
      const actualRiskRate = convertToNumber(
        pricingProducts[index].productItems[itemIndex].actualRiskRate
      );

      validateAndProcessAgentRetention(
        productName,
        actualRiskRate,
        actualFee,
        agentRetention,
        oldAgentRetention,
      );
    }
  };

  const handleTotalDueItemBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    itemIndex: number
  ) => {
    let totalDue = convertToNumber(e.target.value);
    let oldTotalDue = convertToNumber(pricingProducts[index].productItems[itemIndex].totalDueInitial);

    if (totalDue !== oldTotalDue) {
      const productName = `pricingProducts.${index}.productItems.${itemIndex}`;
      const actualFee = convertToNumber(pricingProducts[index].productItems[itemIndex].actualFee);
      const actualRiskRate = convertToNumber(pricingProducts[index].productItems[itemIndex].actualRiskRate);

      validateAndProcessTotalDue(
        productName,
        actualRiskRate,
        actualFee,
        totalDue,
        oldTotalDue,
      );
    }
  };

  const handleRiskRateItemBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    itemIndex: number
  ) => {
    let riskRate = convertToNumber(e.target.value);
    let oldRiskRate = convertToNumber(
      pricingProducts[index].productItems[itemIndex].actualRiskRateInitial
    );
    const productName = `pricingProducts.${index}.productItems.${itemIndex}`;
    const actualFee = convertToNumber(getValues(productName + '.actualFee'));
    const isValid = validateRiskRate(riskRate, actualFee);


    if (!isValid) {
      setValue(getNameString(`${productName}.actualRiskRate`), oldRiskRate, {
        shouldDirty: true,
      });

      showFlashMessage(`Risk Rate of ${formatCurrency(riskRate)} exceeded Premium/Fee. Please enter a different amount.`);

      return;
    }

    if (riskRate !== oldRiskRate) {
      setValue(getNameString(`${productName}.actualRiskRateInitial`), riskRate);
      // if (!isIntegratedPricing) {
      //   setValue(getNameString(`${productName}.calculatedRiskRate`), riskRate);
      // }

      setIsPricingSectionUpdated(false);
      setValue(getNameString(`${productName}.actualFeeUpdated`), true);
      setTimeout(() => {
        setIsPricingSectionUpdated(true);
      });

      // calculate remittance
      if (showRiskRateColumn) {
        let remittance = calculateRemittance(
          pricingProducts[index].productType,
          convertToNumber(pricingProducts[index].liability),
          riskRate,
          agencyStateRemittance?.stdEndorsementSplitType,
          convertToNumber(agencyStateRemittance?.stdEndorsementRemitPct),
          convertToNumber(
            agencyStateRemittance?.stdEndorsementRemitPerThousand
          ),
          convertToNumber(agencyStateRemittance?.minRemitPerThousand),
          convertToNumber(agencyStateRemittance?.cplRemittancePct)
        );

        let totalDue = 0;
        if (hasValue(remittance)) {
          totalDue = remittance || 0;
        }

        setValue(getNameString(`${productName}.totalDue`), totalDue, {
          shouldDirty: true,
        });
        setValue(getNameString(`${productName}.totalDueInitial`), totalDue, {
          shouldDirty: true,
        });
        // if (!isIntegratedPricing) {
        //   setValue(
        //     getNameString(`${productName}.calculatedTotalDue`),
        //     totalDue
        //   );
        // }

        let agentRetention = riskRate - totalDue;

        setValue(
          getNameString(`${productName}.agentRetention`),
          agentRetention,
          {
            shouldDirty: true,
          }
        );
        setValue(
          getNameString(`${productName}.agentRetentionInitial`),
          agentRetention,
          { shouldDirty: true }
        );
        // if (!isIntegratedPricing) {
        //   setValue(
        //     getNameString(`${productName}.calculatedRetention`),
        //     agentRetention
        //   );
        // }
      }
    }
  };

  const savePreviousValue = () => {
    // Save current UI to Calculated Previous Values
    // Update UI with Actual Previous Values
    // Notes: user may change Transaction Type, Property Type, and Municapal Tax Code
    const pricingProducts: PricingProduct[] = getValues("pricingProducts");

    // Save current UI to Calculated Previous Values
    pricingProducts?.forEach((pp, i) => {
      setValue(
        getNameString(`pricingProducts.${i}.calculatedFeePrevious`),
        pp.actualFee
      );
      setValue(
        getNameString(`pricingProducts.${i}.calculatedRiskRatePrevious`),
        pp.actualRiskRate
      );
      setValue(
        getNameString(`pricingProducts.${i}.calculatedRetentionPrevious`),
        pp.agentRetention
      );
      setValue(
        getNameString(`pricingProducts.${i}.calculatedTotalDuePrevious`),
        pp.totalDue
      );
      setValue(
        getNameString(`pricingProducts.${i}.calculatedPremiumTaxPrevious`),
        pp.actualPremiumTax
      );

      pp.productItems?.forEach((ppi, j) => {
        setValue(
          getNameString(
            `pricingProducts.${i}.productItems.${j}.calculatedFeePrevious`
          ),
          ppi.actualFee
        );
        setValue(
          getNameString(
            `pricingProducts.${i}.productItems.${j}.calculatedRiskRatePrevious`
          ),
          ppi.actualRiskRate
        );
        setValue(
          getNameString(
            `pricingProducts.${i}.productItems.${j}.calculatedRetentionPrevious`
          ),
          ppi.agentRetention
        );
        setValue(
          getNameString(
            `pricingProducts.${i}.productItems.${j}.calculatedTotalDuePrevious`
          ),
          ppi.totalDue
        );
        setValue(
          getNameString(
            `pricingProducts.${i}.productItems.${j}.calculatedPremiumTaxPrevious`
          ),
          ppi.actualPremiumTax
        );
      });
    });
  };

  const restorePreviousValue = () => {
    // Update UI with Calc Previous Value
    const pricingProducts: PricingProduct[] = getValues("pricingProducts");

    pricingProducts?.forEach((pp, i) => {
      if (pp.isReadyToBeBilled === 1 && pp.isBilled === 0) {
        // restore pricing product
        setValue(
          getNameString(`pricingProducts.${i}.actualFee`),
          pp.calculatedFeePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.actualFeeInitial`),
          pp.calculatedFeePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.actualRiskRate`),
          pp.calculatedRiskRatePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.actualRiskRateInitial`),
          pp.calculatedRiskRatePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.agentRetention`),
          pp.calculatedRetentionPrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.agentRetentionInitial`),
          pp.calculatedRetentionPrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.totalDue`),
          pp.calculatedTotalDuePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.totalDueInitial`),
          pp.calculatedTotalDuePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.actualPremiumTax`),
          pp.calculatedPremiumTaxPrevious
        );
      }
      // restore pricing product items
      pp.productItems?.forEach((ppi, j) => {
        if (ppi.isReadyToBeBilled === 1 && ppi.isBilled === 0) {
          if (ppi.isFeeError === 1) {
            setValue(
              getNameString(`pricingProducts.${i}.productItems.${j}.actualFee`),
              ppi.actualFeePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.actualFeeInitial`
              ),
              ppi.actualFeePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.actualRiskRate`
              ),
              ppi.actualRiskRatePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.actualRiskRateInitial`
              ),
              ppi.actualRiskRatePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.agentRetention`
              ),
              ppi.agentRetentionPrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.agentRetentionInitial`
              ),
              ppi.agentRetentionPrevious
            );
            setValue(
              getNameString(`pricingProducts.${i}.productItems.${j}.totalDue`),
              ppi.totalDuePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.totalDueInitial`
              ),
              ppi.totalDuePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.actualPremiumTax`
              ),
              ppi.actualPremiumTaxPrevious
            );
          }
          else {
            setValue(
              getNameString(`pricingProducts.${i}.productItems.${j}.actualFee`),
              ppi.calculatedFeePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.actualFeeInitial`
              ),
              ppi.calculatedFeePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.actualRiskRate`
              ),
              ppi.calculatedRiskRatePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.actualRiskRateInitial`
              ),
              ppi.calculatedRiskRatePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.agentRetention`
              ),
              ppi.calculatedRetentionPrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.agentRetentionInitial`
              ),
              ppi.calculatedRetentionPrevious
            );
            setValue(
              getNameString(`pricingProducts.${i}.productItems.${j}.totalDue`),
              ppi.calculatedTotalDuePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.totalDueInitial`
              ),
              ppi.calculatedTotalDuePrevious
            );
            setValue(
              getNameString(
                `pricingProducts.${i}.productItems.${j}.actualPremiumTax`
              ),
              ppi.calculatedPremiumTaxPrevious
            );
          }
        }
      });
    });

    // Set ActualFeeUpdated flag
    setIsPricingSectionUpdated(false);
    setActualFeeUpdated();
    setTimeout(() => {
      setIsPricingSectionUpdated(true);
    });
  };

  const restoreActualValue = () => {
    const pricingProducts: PricingProduct[] = getValues("pricingProducts");

    pricingProducts?.forEach((pp, i) => {
      if (pp.isReadyToBeBilled === 1 && pp.isBilled === 0) {
        // restore pricing product
        setValue(
          getNameString(`pricingProducts.${i}.actualFee`),
          pp.actualFeePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.actualFeeInitial`),
          pp.actualFeePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.actualRiskRate`),
          pp.actualRiskRatePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.actualRiskRateInitial`),
          pp.actualRiskRatePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.agentRetention`),
          pp.agentRetentionPrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.agentRetentionInitial`),
          pp.agentRetentionPrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.totalDue`),
          pp.totalDuePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.totalDueInitial`),
          pp.totalDuePrevious
        );
        setValue(
          getNameString(`pricingProducts.${i}.actualPremiumTax`),
          pp.actualPremiumTaxPrevious
        );
      }
      // restore pricing product items
      pp.productItems?.forEach((ppi, j) => {
        if (ppi.isReadyToBeBilled === 1 && ppi.isBilled === 0) {
          setValue(
            getNameString(`pricingProducts.${i}.productItems.${j}.actualFee`),
            ppi.actualFeePrevious
          );
          setValue(
            getNameString(
              `pricingProducts.${i}.productItems.${j}.actualFeeInitial`
            ),
            ppi.actualFeePrevious
          );
          setValue(
            getNameString(
              `pricingProducts.${i}.productItems.${j}.actualRiskRate`
            ),
            ppi.actualRiskRatePrevious
          );
          setValue(
            getNameString(
              `pricingProducts.${i}.productItems.${j}.actualRiskRateInitial`
            ),
            ppi.actualRiskRatePrevious
          );
          setValue(
            getNameString(
              `pricingProducts.${i}.productItems.${j}.agentRetention`
            ),
            ppi.agentRetentionPrevious
          );
          setValue(
            getNameString(
              `pricingProducts.${i}.productItems.${j}.agentRetentionInitial`
            ),
            ppi.agentRetentionPrevious
          );
          setValue(
            getNameString(`pricingProducts.${i}.productItems.${j}.totalDue`),
            ppi.totalDuePrevious
          );
          setValue(
            getNameString(
              `pricingProducts.${i}.productItems.${j}.totalDueInitial`
            ),
            ppi.totalDuePrevious
          );
          setValue(
            getNameString(
              `pricingProducts.${i}.productItems.${j}.actualPremiumTax`
            ),
            ppi.actualPremiumTaxPrevious
          );
        }
      });
    });

    // Set ActualFeeUpdated flag
    setIsPricingSectionUpdated(false);
    setActualFeeUpdated();
    setTimeout(() => {
      setIsPricingSectionUpdated(true);
    });
  };

  const setActualFeeUpdated = () => {
    const pricingProducts: PricingProduct[] = getValues("pricingProducts");
    const updatedInitialPricingProducts: PricingProduct[] = getValues(
      "updatedInitialPricingProducts"
    );

    // let hasAnyPricingUpdated = false;
    pricingProducts?.forEach((pp, i) => {
      const updatedPP = updatedInitialPricingProducts?.[i];
      const isPricingProductUpdated = isPricingUpdated(pp, updatedPP);
      setValue(
        getNameString(`pricingProducts.${i}.actualFeeUpdated`),
        isPricingProductUpdated
      );
      // if (isPricingProductUpdated) {
      //   hasAnyPricingUpdated = true;
      // }

      pp.productItems?.forEach((ppi, j) => {
        const updatedPPI = updatedPP?.productItems?.[j];
        const isPricingProductItemUpdated = isPricingUpdated(ppi, updatedPPI);
        setValue(
          getNameString(
            `pricingProducts.${i}.productItems.${j}.actualFeeUpdated`
          ),
          isPricingProductItemUpdated
        );
        // if (isPricingProductItemUpdated) {
        //   hasAnyPricingUpdated = true;
        // }
      });
    });

    // if (hasAnyPricingUpdated) {
    //   setIsPricingSectionUpdated(true);
    // } else {
    //   setIsPricingSectionUpdated(false);
    // }
  };

  const isPricingUpdated = (
    currentPricing?: PricingProductDetail,
    initialPricing?: PricingProductDetail
  ) => {
    return (
      convertToNumber(currentPricing?.actualFee) !==
      convertToNumber(initialPricing?.actualFee) ||
      convertToNumber(currentPricing?.actualRiskRate) !==
      convertToNumber(initialPricing?.actualRiskRate) ||
      convertToNumber(currentPricing?.agentRetention) !==
      convertToNumber(initialPricing?.agentRetention) ||
      convertToNumber(currentPricing?.totalDue) !==
      convertToNumber(initialPricing?.totalDue)
    );
  };

  const handleIsOverrideChange = (e: any, value: boolean) => {
    const { checked } = e.target;
    const isCalcRequired = getValues("pricing.isCalculationRequired");

    if (!checked) {
      if (isIntegratedPricing) {
        restorePreviousValue();
      }

      // us# 73923 requirements: call recalculate when if any of these file level info is updated; transaction type, property type,  munical tax code, OR
      // other restore previous value
      const currentTransactiontypeCode = getValues("transactionTypeCode");
      const currentPropertyType = getValues("properties.0.propertyType");
      const currentTaxCode = getValues("properties.0.taxCode");
      const property = initialValues?.properties[0] ?? null;

      const valuesChanged = initialValues.transactionTypeCode !== currentTransactiontypeCode ||
        property?.propertyType !== currentPropertyType ||
        property?.taxCode?.value !== currentTaxCode?.value;

      if (valuesChanged) {
        handleOnCalculate();
      }

      setValue("pricing.overrideReason", null);
      //setOverrideReasonError(false);
      overrideReasonError.current = false;


      // if (!reportButtonDisabled) setReportButtonDisabled(true);  // rlo 6/2/2022 - setReportButton when isOverride is changed
      if (calculateButtonEnabledBeforeOverride || isCalcRequired)
        enableCalculate(true);
      setCalculateButtonEnabledBeforeOverride(false);
    } else {
      if (isIntegratedPricing && isTPSFile) {
        restoreActualValue();
      }

      if (!initialValues.pricing.isOverride) {
        savePreviousValue();
      }

      if (isIntegratedPricing && overrideReasonTypes.length === 0)
        getOverrideReasonTypes();
      setCalculateButtonEnabledBeforeOverride(!calculateButtonDisabled);

      if (overrideReasonPrev.current) {
        setValue("pricing.overrideReason", overrideReasonPrev.current);
      }
      else if (!overrideReason) {
        // setOverrideReasonError(true);
        overrideReasonError.current = true;
        setReportButtonDisabled(true);
      }
      disableCalculate();
    }
    setCalculatePricingJustCompleted(false);
  };

  const handleOverrideReasonChange = (e: any | null) => {
    overrideReasonPrev.current = e.target?.value;
    overrideReasonError.current = false;
  };

  const handleRateTypeChange = (e: any | null, index: number) => {
    enableCalculate();
    checkForUserActionMessage(
      PricingConfigKey.InfoMsgRateType,
      index,
      e.target.value,
      false
    );

    //This is to reset ReIssue flag so no OPN data will be sent in quote request
    const { pricingType, pricingRateType, pricingRateTypeData } = getValues(
      `pricingProducts.${index}`
    );
    const values = getValues() as SCFile;
    const matchingJacketIndex = values.jackets?.findIndex(
      (j) =>
        j.filePricingDetailID === pricingProducts[index].filePricingDetailId
    );

    const isReIssueApplicable = showReissue(
      pricingType,
      pricingRateType,
      pricingRateTypeData
    );

    if (!isReIssueApplicable) {
      if (matchingJacketIndex >= 0) {
        setValue(
          getNameString(
            `jackets.${matchingJacketIndex}.pricingDetail.isReissue`
          ),
          false
        );
        setValue(getNameString(`pricingProducts.${index}.isReissue`), false);
      }
    }
  };

  const handleReissueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    pricing: PricingProduct,
    pricingIndex: number
  ) => {
    const checked = event.target.checked;
    const productIndex = getProductIndex(pricing, pricingIndex)
    setValue(`jackets.${productIndex}.pricingDetail.isReissue`, checked);
    if (checked) {
      if (!getValues("pricing.showReissueEdit")) {
        enableCalculate();
        checkForUserActionMessage(
          PricingConfigKey.InfoMsgReIssue,
          pricingIndex,
          "",
          true
        );
      } else {
        setPolicyDialogState({
          open: true,
          index: pricingIndex,
          triggeredBy: PolicyDialogTriggeredBy.ReIssueCheckbox,
        });
      }
    } else {
      //Reset previous info message
      setValue("pricing.infoMessage", undefined);
      setValue("pricing.infoMessageFontColor", null);
      setValue("pricing.infoMessageFontWeight", null);
      enableCalculate();
    }
  };

  const checkForUserActionMessage = (
    pConfigKey: string,
    index: number,
    rateTypeSelected: string,
    isChecked: boolean
  ) => {
    const rateTypeConfig = pricingConfigs.find(
      (pc) => pc.configKey === pConfigKey
    );

    if (rateTypeConfig) {
      const scfile: any = getValues();
      const pricingProductEdited = pricingProducts[index];

      if (
        (!rateTypeConfig.product ||
          isEqual(rateTypeConfig.product, pricingProductEdited.productType)) &&
        (!rateTypeConfig.formType ||
          isEqual(rateTypeConfig.formType, pricingProductEdited.formType)) &&
        ((isEqual(pConfigKey, PricingConfigKey.InfoMsgRateType) &&
          isEqual(rateTypeSelected, rateTypeConfig.rateType)) ||
          (isEqual(pConfigKey, PricingConfigKey.InfoMsgReIssue) &&
            isChecked)) &&
        (!rateTypeConfig.propertyType ||
          (rateTypeConfig.propertyType &&
            isEqual(
              rateTypeConfig.propertyType,
              scfile.properties[0].propertyType
            ))) &&
        (!rateTypeConfig.transactionType ||
          (rateTypeConfig.transactionType &&
            isEqual(
              rateTypeConfig.transactionType,
              scfile.transactionTypeCode
            )))
      ) {
        let notificationMessage = rateTypeConfig.configValue;

        if (
          rateTypeConfig.liabilityMaxInMillions &&
          rateTypeConfig.liabilityMaxInMillions > 0
        ) {
          if (
            rateTypeConfig.liabilityMaxInMillions >
            pricingProductEdited.liability
          ) {
            const thresholdMessageConfig = pricingConfigs.find(
              (pc) => pc.configKey === PricingConfigKey.ThresholdMessage
            );
            if (thresholdMessageConfig)
              notificationMessage = thresholdMessageConfig.configValue;
          }
        }

        setHeaderNotificationMessage(notificationMessage);
        setNotificationType(NotificationType.Info);
      }
    }
  };

  const handleOnSaveOPN = () => {
    setIsPricingSectionUpdated(false);
    setCalculateButtonDisabled(true);
    setReportButtonDisabled(false);
    setPolicyDialogState({ open: false });

    setTimeout(() => {
      setIsPricingSectionUpdated(true);
    });
  };

  const handleOnCancelOPN = (
    index: number,
    triggeredBy?: PolicyDialogTriggeredBy
  ) => {
    if (triggeredBy === PolicyDialogTriggeredBy.ReIssueCheckbox) {
      //const isReIssueInitial:Boolean = getValues(`updatedInitialPricingProducts.${index}.isReissue`);
      setValue(`pricingProducts.${index}.isReissue`, false);
    }
    setPolicyDialogState({ open: false });
  };

  const resetFields = () => {
    setValue("pricing.isSimultaneousRate", false);
    setValue("pricing.isOverride", false);
    setValue("pricing.overrideReason", "");
    setValue("pricing.rateEffectiveDate", undefined);

    resetPricingFields({
      isSimultaneousRate: false,
      isOverride: false,
      overrideReason: "",
      rateEffectiveDate: undefined
    });
    disableCalculate();
  };

  const setReportButton = useCallback(() => {
    // default Report button to be disabled
    setDOMReportButton(true);
    setReportButtonDisabled(true);

    if (pricing.isReadyToBeBilled === 1) {
      if (isIntegratedPricing) {
        // Integrated Pricing
        if (!calculateButtonDisabled) {
          setDOMReportButton(true);
          setReportButtonDisabled(true);
          return;
        }

        if (isOverride) {
          // Override -- override reason must be selected to enable Report button
          if (overrideReason) { 
            setDOMReportButton(false);
          }
          else {
             setDOMReportButton(true); // Override but no reason selected
             setReportButtonDisabled(true);
          }
        } else {
          // Not Override
          setDOMReportButton(false);
        }
      } else {
        // Manual Pricing
        setDOMReportButton(false);
      }
      return;
    }

    // pricingProducts?.forEach((p: PricingProduct, index: number) => {
    //   if (p.actualFeeUpdated) {
    //     if (isIntegratedPricing) {
    //       // Integrated Pricing
    //       if (!calculateButtonDisabled) {
    //         setReportButtonDisabled(true);
    //         return;
    //       }

    //       if (isOverride) {
    //         // Override -- override reason must be selected to enable Report button
    //         if (overrideReason) setReportButtonDisabled(false);
    //         else setReportButtonDisabled(true); // Override but no reason selected
    //       } else {
    //         // Not Override
    //         setReportButtonDisabled(false);
    //       }
    //     } else {
    //       // Manual Pricing
    //       setReportButtonDisabled(false);
    //     }
    //     return;
    //   }
    //   p.productItems?.forEach((pi: PricingProductDetail) => {
    //     if (pi.actualFeeUpdated) {
    //       if (isIntegratedPricing) {
    //         // Integrated Pricing
    //         if (!calculateButtonDisabled) {
    //           setReportButtonDisabled(true);
    //           return;
    //         }

    //         if (isOverride) {
    //           // Override - override reason must be selected to enable Report button
    //           if (overrideReason) setReportButtonDisabled(false);
    //           else setReportButtonDisabled(true); // Override but no reason selected
    //         } else {
    //           // No Override
    //           setReportButtonDisabled(false); // if not override
    //         }
    //       } else {
    //         // Manual Pricing
    //         setReportButtonDisabled(false); // If not IntegratedPricing
    //       }
    //       return;
    //     }
    //   });
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calculateButtonDisabled,
    isIntegratedPricing,
    isOverride,
    overrideReason,
    pricing,
  ]);

  const isEmptyTable = !pricingProducts || pricingProducts.length === 0;
  
  const showHeaderNotificationMessage =
    (isIntegratedPricing &&
      !isFileReadOnly &&
      headerNotificationMessage &&
      showCalculateButton(isIntegratedPricing, isFileLocked, isFileReadOnly)
    ) ||
    (pricingProducts &&
      pricingProducts.length > 0 &&
      pricingProducts[0].productType === ProductType.StandaloneEndorsement);


  const showPricingNotificationMessage =
    // displayMessage &&
    hasRemainingPricingErrorMessage &&
    isIntegratedPricing &&
    !isFileReadOnly &&
    !isFileLocked &&
    // !headerNotificationMessage &&
    !showHeaderNotificationMessage &&
    !isOverride; // &&
    // !errorMessage;

  const getOriginalPolicyMode = (index: number) => {
    const isBilled = getValues(`pricingProducts.${index}.isBilled`);
    return Boolean(isBilled);
  };

  // const handleOnDebugClick = () => {
  //   console.log("pricingProducts: ", pricingProducts);
  //   console.log("getValues():", getValues());
  // }

  useEffect(() => {
    document.addEventListener("documentReset", resetPricingData);
    return () => {
      document.removeEventListener("documentReset", resetPricingData);
    };
  });

  useEffect(() => {
    document.addEventListener(DOMEventType.FILE_JUST_LOADED, resetPricingData);
    return () => {
      document.removeEventListener(
        DOMEventType.FILE_JUST_LOADED,
        resetPricingData
      );
    };
  });

  useEffect(() => {

    if (isOverride) {
      if (!overrideReason)
        overrideReasonError.current = true;
      else {
        overrideReasonPrev.current = overrideReason;
        if (!overrideReasonInitialRef.current ||
          (overrideReasonInitialRef.current &&
            overrideReasonInitialRef.current !== overrideReasonInitial)) {
          overrideReasonInitialRef.current = overrideReasonInitial;
          isTPSCalculated.current = false;
        }
      }
    } else {
      isTPSCalculated.current = false;
      isOverrideReasonTypeUpdated.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverride, isIntegratedPricing, overrideReason, overrideReasonInitial]);

  useEffect(() => {
    if (isPricingRecalculateRequired && calculateButtonDisabled)
      enableCalculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPricingRecalculateRequired]);

  useEffect(() => {
    if (!isPricingCalculated) return;
    setIsPricingSectionUpdated(false);
    setActualFeeUpdated();
    setTimeout(() => {
      setIsPricingSectionUpdated(true);
    });
  }, [isPricingCalculated]);

  useEffect(() => {
    if (!pricingNotificationUpdated) return;

    // const currentErrorMessage = getValues("pricing.errorMessage");
    // const currentInfoMessage = getValues("pricing.infoMessage");

    // if (currentErrorMessage) {
    //   setNotificationType(NotificationType.Error);
    //   setHeaderNotificationMessage(currentErrorMessage);
    //   if (isCalculationRequired) enableCalculate(false, true);

    //   return;
    // }

    // if (currentInfoMessage) {
    //   setNotificationType(NotificationType.Info);
    //   setHeaderNotificationMessage(currentInfoMessage);
    //   if (isCalculationRequired) enableCalculate(false, true);

    //   return;
    // }
    const isCalcRequiredLatest = getValues("pricing.isCalculationRequired");
    isCalcRequiredLatest
      ? enableCalculate()
      : setHeaderNotificationMessage("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pricingNotificationUpdated,
    // getValues("pricing.errorMessage"),
    // getValues("pricing.infoMessage"),
    // errorMessage,
    // infoMessage,
  ]);

  useEffect(() => {
    if (reportPricingInProgress || calculatePricingInProgress) return;

    // If no iisued products, disable report button
    if (noIssuedProducts) {
      setReportButtonDisabled(true);
      return;
    } else if (calculatePricingJustCompleted) {
      //If no active jackets, disable report button
      const hasActiveJacket = pricingProducts?.find(
        (pp) => pp.productType === ProductType.Jacket
      );
      if (!hasActiveJacket) {
        setReportButtonDisabled(true);
        return;
      }
    }
    setReportButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calculatePricingInProgress,
    calculatePricingJustCompleted,
    noIssuedProducts,
    reportPricingInProgress,
    setReportButton,
    pricing.isReadyToBeBilled,
  ]);

  useEffect(() => {
    if (!combinedQNAs || calculatePricingInProgress) return;

    const uiWithUnansweredQuestions = combinedQNAs?.filter((ui: any) =>
      ui.questions?.filter((question: any) => question && !question.answerValue)
    );

    if (
      currentAction === ProductAction.ReportPricing &&
      !reportPricingInProgress &&
      uiWithUnansweredQuestions.length === 0
    ) {
      setCurrentAction(ProductAction.None);
      setIsQAOpen(false);
      return;
    }

    if (uiWithUnansweredQuestions.length === 0) {
      setIsQAOpen(false);
      return;
    }
    if (
      currentAction === ProductAction.ReportPricing &&
      reportPricingInProgress
    ) {
      setBypassDefaultLoader(true);
      setIsQAOpen(true);
    }
  }, [
    setBypassDefaultLoader,
    combinedQNAs,
    currentAction,
    reportPricingInProgress,
    calculatePricingInProgress,
  ]);

  useEffect(() => {
    if (noIssuedProducts) {
      resetFields();
    }
  }, [noIssuedProducts, isIssuedProductsCountUpdated]);

  useEffect(() => {
    if (isIntegratedPricing && overrideReasonTypes.length === 0)
      getOverrideReasonTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOverride) {
      isTPSCalculated.current = overrideReason === "TPSCALCULATED";
    }
    else {
      if (isOverrideReasonTypeUpdated.current) {
        isTPSCalculated.current = false;
        isOverrideReasonTypeUpdated.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!pricingProducts || pricingProducts?.length === 0) return;
    //const saendoNotificationMessage = "Pricing cannot be returned for standalone endorsements. Please contact your Underwriter for pricing and manually enter below.";
    if(pricingProducts[0].productType === ProductType.StandaloneEndorsement){      
      var endorsementsWithoutActualFee = pricingProducts.find(pp => pp.productItems?.find(pi => pi.actualFee === 0 || isNaN(pi.actualFee)));            
      if(endorsementsWithoutActualFee){        
        setTimeout(() => {
          setHeaderNotificationMessage("Pricing cannot be returned for standalone endorsements. Please contact your Underwriter for pricing and manually enter below."); 
        }, 100);                
      }else{        
        setTimeout(() => {
          setHeaderNotificationMessage("");
        }, 100);
      }     
    }
  }, [pricingProducts, isPricingUpdated])
  // rlo 3/16/2022 - don't use isCalculationRequired in the useEffect parameter here, because
  // the value (which is using useWatch) will be set to unidentified right after form reset (after save/revise/issue, etc)
  useEffect(() => {
    const currentIsCalculationRequired = getValues(
      "pricing.isCalculationRequired"
    );

    if (
      currentIsCalculationRequired !== undefined &&
      !currentIsCalculationRequired
    ) {
      disableCalculate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("pricing.isCalculationRequired"), isCalculationRequired]);

  useEffect(() => {
    if (isUpdated) {
      setSimultaneousRate();
      setValue("pricing.isUpdated", false);
    }
  }, [isUpdated]);

  // useEffect(() => {
  //   if (displayMessage && !errorMessage) {
  //     setPricingNotificationMessage(message);
  //     setPricingNotificationType(type);
  //   } else {
  //     setPricingNotificationMessage("");
  //   }
  // }, [displayMessage, message, type]);
  useEffect(() => {
    if (hasRemainingPricingErrorMessage) {
      setPricingNotificationMessage(PRICING_GENERIC_ERROR_MESSAGE);
      setPricingNotificationType(type);
    }
    else {
      setPricingNotificationMessage("");
    }
  },[hasRemainingPricingErrorMessage, type])

  // rlo 5/20/2022 - isPricingInProgressRef is required to be used within setInterval, because isPricingInProgress is not updated within setInterval
  useEffect(() => {
    isPricingInProgressRef.current = isPricingInProgress;
  }, [isPricingInProgress]);

  useEffect(() => {
    return () => {
      closeFlashMessage();
    };
  }, []);

  useEffect(() => {

    if (isTPSCalculated.current ||
      overrideReasonTypes?.length === 0)
      return;

    if (!isTPSCalculated.current &&
      !isOverrideReasonTypeUpdated.current &&
      overrideReasonInitialRef.current !== "TPSCALCULATED") {
      const newList = overrideReasonTypes.map((type) => {
        return {
          overrideReasonTypeName: type.text,
          overrideReasonTypeCode: type.value,
          selected: false,
          overrideReasonTypeDescription: type.desc,
          disabled: type.value === "TPSCALCULATED",
        };
      });

      setTimeout(() => {
        updateOverrideReasonTypes(newList);
      }, 10);
      isOverrideReasonTypeUpdated.current = true;
    }
  },
    [
      overrideReasonTypes,
      overrideReason,
      updateOverrideReasonTypes,
      overrideReasonInitial
    ]);



  // console.log("pricingProducts: ", pricingProducts);

  return (
    <PricingContainer>
      {isIntegratedPricing && (
        <IntegratedPricing
          noIssuedProducts={noIssuedProducts}
          isOverride={isOverride}
          overrideReasonError={overrideReasonError.current} //{overrideReasonError}
          enableCalculate={enableCalculate}
          onIsOverrideChange={handleIsOverrideChange}
          onOverrideReasonChange={handleOverrideReasonChange} // setOverrideReasonError(false)}
          isOverrideLocked={
            isFileReadOnly ||
            isFileLockedWithoutUpdateablePricingProducts(
              isFileLocked,
              pricingProducts,
              isFileReadOnly
            )
          }
          isFileLocked={isFileLocked}
        />
      )}

      {(showHeaderNotificationMessage || showPricingNotificationMessage) && (
        <TextIconContainer>
          {showHeaderNotificationMessage && (
            <PricingTableHeaderNotification
              show={showHeaderNotificationMessage}
              notificationType={notificationType}
              message={headerNotificationMessage}
              infoMessageFontColor={pricing.infoMessageFontColor}
              infoMessageFontWeight={pricing.infoMessageFontWeight}
            />
          )}
          {showPricingNotificationMessage && (
            <>
              {/* <ReportProblemIcon style={{ color: messageIconColor }} /> */}
              <PricingTableHeaderNotification
                show={true} // displayMessage}
                notificationType={pricingNotificationType}
                message={pricingNotificationMessage}
                infoMessageFontColor={
                  pricing.infoMessageFontColor ?? colors.red02
                }
                infoMessageFontWeight={pricing.infoMessageFontWeight}
              />
            </>
          )}
        </TextIconContainer>
      )}
      <StyledTableContainer>
        <Table
          className={`pricing-table ${
            pricingTableContainerDisabled ? "pricing-table-disabled" : ""
          }`}
        >
          <PricingTableHeader
            hasIssuedProducts={pricingProducts.length > 0}
            displayLiabilityColumn={showLiabilityColumn}
            displayRateTypeColumn={showRateTypeColumn}
            displayReissueColumn={showReissueColumn}
            displayRiskRateColumn={showRiskRateColumn}
            displayTransCodeColumn={showTransCodeColumn}
            showTax={showTax}
            displayFieldIcon={displayFieldIcon}
          />
          {isEmptyTable ? (
            <PricingEmptyBodyRow />
          ) : (
            <PricingTableBody
              products={pricingProducts}
              displayLiabilityColumn={showLiabilityColumn}
              displayRateTypeColumn={showRateTypeColumn}
              displayTransCodeColumn={showTransCodeColumn}
              displayReissueColumn={showReissueColumn}
              displayRiskRateColumn={showRiskRateColumn}
              displayFieldIcon={displayFieldIcon}
              isIntegratedPricing={isIntegratedPricing}
              isOverride={isOverride}
              hasErrorMessage={!!headerNotificationMessage} // || errorMessage}
              handleRateTypeChange={handleRateTypeChange}
              handleReissueChange={handleReissueChange}
              handleActualFeeBlur={handleActualFeeBlur}
              // handleActualFeeOnChange={handleActualFeeOnChange}
              handleRiskRateBlur={handleRiskRateBlur}
              handleAgentRetentionBlur={handleAgentRetentionBlur}
              handleTotalDueBlur={handleTotalDueBlur}
              handleActualFeeItemBlur={handleActualFeeItemBlur}
              handleRiskRateItemBlur={handleRiskRateItemBlur}
              handleAgentRetentionItemBlur={handleAgentRetentionItemBlur}
              handleTotalDueItemBlur={handleTotalDueItemBlur}
              handleIsReissueClick={(index) =>
                setPolicyDialogState({
                  open: true,
                  index,
                  triggeredBy: PolicyDialogTriggeredBy.ReIssueIcon,
                })
              }
              setIsPricingSectionUpdated={setIsPricingSectionUpdated}
            />
          )}
          {isEmptyTable ? (
            <TableFooter>
              <TableRow>
                <StyledTableFooterCell colSpan={5}>
                  <div>Totals</div>
                </StyledTableFooterCell>
              </TableRow>
            </TableFooter>
          ) : (
            <PricingTableFooter
              isIntegratedPricing={isIntegratedPricing}
              isPricingSectionUpdated={isPricingSectionUpdated}
              showTax={showTax}
              hasIssuedProducts={pricingProducts.length > 0}
              displayLiabilityColumn={showLiabilityColumn}
              displayRateTypeColumn={showRateTypeColumn}
              displayReissueColumn={showReissueColumn}
              displayTransCodeColumn={showTransCodeColumn}
              displayRiskRateColumn={showRiskRateColumn}
              displayFieldIcon={displayFieldIcon}
              hasFileLockedWithUpdateablePricingItem={
                hasFileLockedWithUpdateablePricingItem
              }
              // hasFileLockedWithUpdateablePricingItem={isFileLockedWithUpdateblePricingProducts(
              //   isFileLocked,
              //   pricingProducts
              // )}
            />
          )}
        </Table>
      </StyledTableContainer>
      <PricingButton
        onNavigate={onNavigate}
        reportDisabled={reportButtonDisabled && !hasMessageError}
        calculateDisabled={calculateButtonDisabled}
        onReport={handleReportPricing}
        onUndoReport={handleUndoReport}
        onCalculate={handleOnCalculate}
        onReportingComplete={handleReportingComplete}
        integratedPricing={isIntegratedPricing}
        isReivising={isRevisingMode}
        isPricingSectionUpdated={isPricingSectionUpdated}
        hasFileLockedWithUpdateablePricingItem={
          hasFileLockedWithUpdateablePricingItem
        }
        displayRiskRateColumn={showRiskRateColumn}
      />
      {/*  DEBUG ONLY */}
      {/* <ActionButton
        color="primary"
        variant="outlined"
        onClick={handleOnDebugClick}
      >
        Debug
      </ActionButton> */}
      <StyledCheckboxInputField
        hidden={true}
        name="pricing.prevUserSimulteneousRate"
      />
      {openReportPricingDialog && (
        <ConfirmationDialogWithProgressbar
          autoClose={true}
          title={statusMessage}
          closeButtonText="Done! Click to Continue"
          isOpen={openReportPricingDialog}
          onClose={handleCloseReportPricingDialog}
          requestId={reportPricingRequestId}
          runProgress={runReportPricingProgress}
          progressBaseline={reportPricingProgressBaseline}
        />
      )}
      <ConfirmationDialog
        isOpen={confirmationTaxDialog.open}
        confirmationMessage="A new tax amount has been received. Would you like to display the new tax amounts?"
        onYes={handleAcceptTaxAmountStrafe}
        onNo={handleDeclineTaxAmountStrafe}
      />
      <QADialog
        isOpen={isQAOpen}
        onClose={handleCloseQnA}
        onSubmit={handleCompletedQnA}
      />
      <ErrorDialog
        isOpen={openErrorDialog}
        confirmationMessage={resultErrorMessage}
        correlationId={correlationId}
        onYes={handleOnYesErrorDialog}
      />
      <PricingOriginalPolicyDialog
        isOpen={policyDialogState.open}
        index={policyDialogState.index}
        onClose={() =>
          handleOnCancelOPN(
            policyDialogState.index || 0,
            policyDialogState.triggeredBy
          )
        }
        onSave={handleOnSaveOPN}
        disabled={getOriginalPolicyMode(policyDialogState.index || 0)}
      />
      <FlashMessage
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // autoHideDuration={Number(
        //   UIConstants.FLASH_MESSAGE_AUTO_HIDDEN_DURATION
        // )}
        onClose={handleFlashMessageClose}
        action={flashMessageAction}
      />
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={() => setOpenPdfViewer(false)}
        pdfDocuments={pdfDocuments}
      />
      <ReportAndPayConfirmationDialog
        isOpen={openReportAndPayConfirmationDialog}
        onContinue={() => setOpenReportAndPayConfirmationDialog(false)}
        reportingResult={reportingResultRef.current}
      />
    </PricingContainer>
  );
};

export default PricingData;
