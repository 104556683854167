import { PricingProductSortOrder2, ProductItemType } from "./enum";

export const getSortOrder2 = (productItemType: string):PricingProductSortOrder2 => {
  let sortOrder2 = PricingProductSortOrder2.Product;
  switch (productItemType) {
    case ProductItemType.Endorsement:
      sortOrder2 = PricingProductSortOrder2.Endorsement;
      break;
    case ProductItemType.JacketAdditionalCharge:
      sortOrder2 = PricingProductSortOrder2.AdditionalCharge;
      break;
    case ProductItemType.JacketSpecialCharge:
      sortOrder2 = PricingProductSortOrder2.SpecialCharges;
      break;        
    default: 
      sortOrder2 = PricingProductSortOrder2.Product;
  }

  return sortOrder2;
}