export enum UIConstants {
  JACKET_FORMDETAIL_BORROWER_NAME_MAX_LENGTH = 255,
  CHECK_PROCESS_IN_PROGRESS_INTERVAL_MILISECONDS = 250, // 0.25 second
  WAITING_PROCESS_IN_PROGRESS_MAX_MILISECONDS = 30000, // 30 seconds
  FLASH_MESSAGE_AUTO_HIDDEN_DURATION = 3000, // 3 seconds
  LOADING_IN_PROGRESS_MESSAGE = "Please wait while your data is being prepared",
  LOADING_RECALC_PREMIUM_MESSAGE = "Please wait while we recalculate your premiums",
  SAVE_IN_PROGRESS_MESSAGE = "Please wait while saving your file",
  SAVE_DOCUMENT_IN_PROGRESS_MESSAGE = "Please wait while saving your policy document",
  SAVE_FILE_STATUS_MESSAGE = "Please wait while updating your File Status...",
  SAVE_PROFILE_IN_PROGRESS_MESSAGE = "Please wait while saving your profile",
  UNDO_REPORT_REQUEST_SUBMITTING_MESSAGE = "Please wait while submitting your request...",
  UNDO_REPORT_ERROR_MESSAGE = "There was a problem with the Undo Report button. Please try again or contact the Agency Support Center for assistance.",
  VIEW_DOCUMENT_ERROR_MESSAGE = "There was a problem displaying your document. Please try again or contact the Agency Support Center for assistance.",
  EFFECTIVE_DATE_NOT_WITHIN_30_DAYS_ERROR_MESSAGE = "The Effective Date must be within 30 days.",
  EFFECTIVE_DATE_NOT_VALID_ERROR_MESSAGE = "The effective date is not valid based on the Agency's contract date.",
  FORM_NOT_VALID_FOR_SELECTED_EFFECTIVE_DATE = "The Form is not valid for the selected Effective Date.  Please Void the Jacket and Issue a New One.",
}

export enum DOMEventType {
  FILE_JUST_LOADED = "fileJustLoaded",
  APP_RELOAD_REQUIRED = "appReloadRequired",
}

export enum LocalStorageKeys {
  COLUMNS_FILES = "columns-files",
  COLUMNS_FILES_HIDDEN = "columns-files-hidden",
  COLUMNS_REPORT_PAY = "columns-report-pay",
  COLUMNS_REPORT_PAY_HIDDEN = "columns-report-pay-hidden",
  COLUMNS_PENDING_PAY = "columns-pending-pay",
  COLUMNS_PENDING_PAY_HIDDEN = "columns-pending-pay-hidden",
  COLUMNS_IMAGE_ARCHIVE = "columns-image-archive",
  COLUMNS_IMAGE_ARCHIVE_HIDDEN = "columns-image-archive-hidden",
}

export enum SessionStorageKeys {
  APP_VERSION = "app-version",
  APP_VERSION_PENDING = "app-version-pending",
  APP_RELOAD_REQUIRED = "app-reload-required",
  FEATURE_CONFIG = "feature-config",
  GENERAL_CONFIG = "general-config",
  GLOBAL_ACCESS_SELECTED_AGENCY = "selectedAgency",
  USE_PRIOR_SEARCH_CRITERIA = "usePriorSearchCriteria",
  USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH = "usePriorSearchCriteriaAdvanceSearch",
  REPORT_PAY_STORED_CRITERIA = "reportPayStoredCriteria",
  LIST_FILE_STORED_CRITERIA = "listFileStoredCriteria",
  PENDING_PAY_STORED_CRITERIA = "pendingPayStoredCriteria",
  SELECTED_FILES_REPORT_PAY = "selectedFilesReportPay",
  SELECTED_FILES_PENDING_PAY = "selectedFilesPendingPay",
  LANDING_PAGE = "landing_page"
}

export enum CreateFileProviderActionType {
  INIT = "INIT",
  UPDATE_PRICING_IN_PROGRESS = "UPDATE_PRICING_IN_PROGRESS",
}

export enum ProductType {
  AllProducts = "",
  Aal = "AAL",
  Cpl = "CPL",
  Jacket = "JACKET",
  Endorsement = "ENDORSEMENT", // not part of table lookup
  StandaloneEndorsement = "SAENDO",
}

export enum ProductItemType {
  Endorsement = "ENDO",
  JacketAdditionalCharge = "JACKETADDLCHG",
  JacketSpecialCharge = "JACKETSPCLCHG",
}

export enum ProductTabNameExtension {
  PolicyUpload = "POLICY UPLOAD"
}

export enum ProductTabTitle {
  Aal = "AAL",
  Cpl = "CPL",
  Jacket = "Jacket",
  StandaloneEndorsement = "Standalone Endorsement",
  PolicyUpload = "Policy Upload",
}

export enum PolicyImageTabName {
  UploadImages = "Upload Images",
  ImageArchive = "Image Archive",
}

export enum JacketFormType {
  Binder = "Binder",
  Commitment = "Commitment",
  Guarantee = "Guarantee",
  Loan = "Loan",
  Owners = "Owners",
}

export enum JacketFormDetailName {
  "AddendumAttached" = "Addendum Attached",
  "BorrowerNamesAndVesting" = "Borrower Names and Vesting",
  "MortgageAmount" = "Mortgage Amount",
}

export enum OrderStatusType {
  Cancelled = "CANCELLED",
  Error = "ERROR",
  Issued = "ISSUED",
  Pending = "PENDING",
  UndoVoidPending = "UNDOVOIDPEND",
  Voided = "VOIDED",
}

export enum EndorsementStatusType {
  Cancelled = "CANCELLED",
  Error = "ERROR",
  Issued = "ISSUED",
  Pending = "PENDING",
  Submitted = "SUBMITTED",
  Voided = "VOIDED",
}

export enum OrderStatusTypeName {
  Pending = "Pending",
  Issued = "Issued",
}

export enum FileStatusType {
  Cancelled = "CANCELLED",
  Inactive = "INACTIVE",
  Open = "OPEN",
  OpenedInError = "OPENEDINERROR",
  ReportPaid = "REPORTEDPAID",
  ReportPending = "REPORTEDPENDING",
}

export enum ImageArchiveType {
  UploadDate = "UPLOADED_DATE",
}
export enum FileStatusTypeName {
  Cancelled = "Cancelled",
  Inactive = "Inactive",
  Open = "Open",
  OpenedInError = "Opened In Error",
  ReportPaid = "Reported/Paid",
  ReportPending = "Reported/Pending",
}

export enum ReportingOptionType {
  PayByCheck = "PAYBYCHECK",
  EPay = "EPAY",
  ReportOnly = "REPORTONLY",
  AutoReport = "AUTOREPORT",
}

export enum PaymentSheetOptionType {
  Default = "PRODUCT",  // for now
  File = "FILE",
  Product = "PRODUCT",
}

export enum MapActionType {
  SaveFile = "SaveFile",
  ProductAction = "ProductAction",
  RetrievePricingData = "RetrievePricingData",
  SaveJacketOPN = "SaveJacketOPN",
  CheckFormDirty = "CheckFormDirty",
  SaveFileStatus = "SaveFileStatus",
}

export enum ProductAction {
  Issue = "Issue",
  Revise = "Revise",
  Ppe = "Ppe",
  ReportPricing = "ReportPricing",
  UndoReport = "UndoReport",
  ReportAndPayByCheck = "ReportAndPayByCheck",
  CalculatePricing = "CalculatePricing",
  CalculateItem = "CalculateItem",
  CalculateItems = "CalculateItems",
  Void = "Void",
  None = "None",
}

export enum PartyType {
  Individual = "INDIVIDUAL",
  BusTrustEstate = "BUSTRUSTESTATE",
  Attorneys = "ATTORNEY",
  SettlementCompany = "SETTLEMENTCOMPANY",
  SecondaryAgency = "SECONDARYAGENCY",
}

export enum SearchPartyType {
  Attorney = "ApprovedAttorney",
  SettlementCompany = "SettlementCompany",
  SecondaryAgency = "SecondaryAgency",
}

export enum PartyRoleType {
  BuyerBorrower = "BUYER/BORROWER",
  Seller = "SELLER",
  Lender = "LENDER",
  AdditionalParty = "ADDITIONALPARTY",
}

export enum PartyRoleTypeName {
  BuyerBorrower = "Buyer/Borrower",
  Seller = "Seller",
  Lender = "Lender",
  AdditionalParty = "ADDITIONAL PARTY",
}

export enum ProductTypeName {
  CPL = "CPL",
  AAL = "AAL",
  JACKET = "Jacket",
  STANDALONE_ENDORSEMENT = "Standalone Endorsement",
}

export enum SequenceType {
  First = "FIRST",
  Second = "SECOND",
}

export enum IssueType {
  All = "All",
  Single = "Single",
}

export enum PricingColumnName {
  Product = "Product",
  ActualFee = "ActualFee",
  RiskRate = "ActualRiskRate",
  AgentRetention = "AgentRetention",
  TotalDue = "TotalDue",
}

export enum Country {
  USA = "840",
}

export enum RemittanceSplitType {
  Flat = "FLAT %",
  CentPerThousand = "CENTS PER THOUSAND",
  Staggered = "STAGGERED",
}

export enum DateTypeCode {
  Created = "CREATED_DATE",
  Modified = "MODIFIED_DATE",
  EffectiveDate = "EFFECTIVE_DATE",
  IssueDate = "ISSUE_DATE",
  ReportedDate = "BILLED_DATE",
  UploadDate = "UPLOADED_DATE",
}

export enum CacheKey {
  MsalInstance = "STEWART_MSAL_INSTANCE",
}

export enum PricingType {
  All = "All",
  Product = "Product",
  ProductItem = "ProductItem",
  AdditionalCharge = "AdditionalCharge",
  SpecialCharge = "SpecialCharge",
  Tax = "Tax",
}

export enum PricingProductSortOrder {
  JacketLoanOwner = "01",
  JacketOther = "02",
  CPL = "03",
  AAL = "04",
  StandaloneLoanOwner = "05",
  StandaloneOther = "06",
}

export enum PricingProductSortOrder2 {
  Product = 1,
  Endorsement = 2,
  AdditionalCharge = 3,
  SpecialCharges = 4,
  Tax = 9,
}

export enum NotificationType {
  Info = "Info",
  Error = "Error",
}

export enum PricingConfigKey {
  IsIntegratedPricing = "IsIntegratedPricing",
  DefaultErrorMessage = "DefaultErrorMessage",
  RiskRate = "RiskRate",
  ReIssue = "ReIssue",
  ReIssueEdit = "Pricing:ReIssue:Edit",
  SimultaneousRate = "SimultaneousRate",
  InfoMsgRateType = "InfoMsg:RateType",
  InfoMsgReIssue = "InfoMsg:ReIssue",
  ThresholdMessage = "ThresholdMessage",
  TaxCode = "Tax",
  TaxDocument = "TaxCodesDocument",
  LiabilityLimit = "LiabilityLimit",
  LiabilityLabel = "OPN:Liability:Label",
  OPNLiability = "OPN:Liability",
  OPNCoverageType = "OPN:CoverageType",
  OPNCoverageTypeLabel = "OPN:CoverageType:Label",
  OPNPolicyDate = "OPN:PolicyDate",
  OPNPolicyDateLabel = "OPN:PolicyDate:Label",
  OPNPrevPolicyLast10Yrs = "OPN:PrevPolicyLast10Yrs",
  OPNWindowTitle = "OPN:Window:Title",
  TransCode = "TransCode",
  Leasehold = "Leasehold",
  ReIssueLabel = "Pricing:ReIssue:Label",
  ReIssueLabelText = "Pricing:ReIssue:LabelText",
  CheckBox = "Pricing:ReIssue:Checkbox",
  CoInsurance = "CoInsurance",
  CallStrafeForRemittance = "CallStrafeForRemittance",
  JacketFormDefault = "DefaultForm",
  JacketTransCodeDefault = "DefaultTransCode",
  MultipleLoans = "MultipleLoans",
  CPLLiability = "CPL:Liability",
}

export enum TaxCodeSearchBy {
  City = "city",
  County = "county",
  Default = "DEFAULT",
}

export enum TaxCodePremium {
  Default = "9998",
}

export enum VoidReason {
  CreatedInError = "Created in Error",
  EnteredInError = "Entered in Error",
}

export enum ReportAndPayAction {
  ReportOnly = "REPORTONLY",
  ReportAndPayByCheck = "REPORTANDPAYBYCHECK",
  PayByCheck = "PAYBYCHECK",
}

export enum ReportType {
  ReportOnly = "REPORTONLY",
  ReportPayCheck = "PAYBYCHECK",
  ReportPayEPay = "EPAY",
  AutoReport = "AUTOREPORT",
}

export enum PaymentsFileExportCriteriaType {
  ReportAndPayCriteria = "ReportAndPayCriteriaSearch",
  ReportAndPayKeyword = "ReportAndPayKeywordSearch",
  PendingPaymentsCriteria = "PendingPaymentsCriteriaSearch",
  PendingPaymentsKeyword = "PendingPaymentsKeywordSearch",
}

export enum RuleCondition {
  IsRequired = "IsRequired",
  IsAllowed = "IsAllowed",
  IsNotAllowed = "IsNotAllowed",
}

export enum OverrideReasonType {
  CoInsurance = "COINSURANCE",
}

export enum CompanyContractDateStatus {
  Active = "Active",
  Amended = "Amended",
  Cancelled = "Cancelled",
}

export enum PageType {
  MyFilesPage = "MYFILESPAGE",
  ReportPayPage = "REPORTPAYPAGE",
  PendingPayPage = "PENDINGPAYPAGE",
  ImageArchive = "IMAGEARCHIVE"
}

export enum SiteErrorType {
  BadAccount = "BADACCOUNT",
  NetworkError = "NETWORK",
  NotFound = "NOTFOUND",
  ErrorBoundary = "ERRORBOUNDARY",
  NoAccess = "NOACCESS",
  NoModuleAccess = "NOMODULEACCESS"
}

export enum UserRequestAuthorizationType {
  Authorized = "AUTHORIZED",
  ProvidedAuthorization = "PROVIDED_AUTHORIZATION",
  NotAuthorized = "NOT_AUTHORIZED",
}

export enum DownloadFileType {
  Mp4 = "mp4",
  Pdf = "pdf",
  Xlsx = "xlsx",
}

export enum AgencyStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum SignatureLevelType {
  AgencyWide = "AGENCY_WIDE",
  UserOnly = "USER_ONLY"
}

export enum PERMISSIONS {
  CREATE_FILE_ACCESS= 'CREATE_FILE_ACCESS',
  FILES_SEARCH= 'FILES_SEARCH',
  HELP_ACCESS= 'HELP_ACCESS',
  VIEW_BILLING= 'VIEW_BILLING',
  POLICYIMAGES_ACCESS= 'POLICYIMAGES_ACCESS',
  RESOURCES_ACCESS= 'RESOURCES_ACCESS',
  VIEW_REPORTS= 'VIEW_REPORTS',
  POLICYIMAGES_SEARCH ='POLICYIMAGES_SEARCH',
  POLICYIMAGES_DELETE ='POLICYIMAGES_DELETE',
  POLICYIMAGES_MODIFY ='POLICYIMAGES_MODIFY',
  SIGNATURES_MANAGE= 'SIGNATURES_MANAGE',
  EXPORT_DATA_PORTAL = 'EXPORT_DATA_PORTAL',
  POLICYIMAGES_VIEWDOCS ='POLICYIMAGES_VIEWDOCS',
  SITE_ACCESS = 'SITE_ACCESS',
  ADMIN_ACCESS = 'ADMIN_ACCESS',
  POLICY_ACCESS = 'POLICY_ACCESS',
}

export enum ProgressImageType {
  linear = "LinearProgress",
  circular = "CircularProgress",
}

export enum NavigateToType {
  Url = "URL",
  Tab = "TAB",
  Button = "BUTTON",
  Adb2c = "ADB2C",
}

export enum ActionResultStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
}
